import BoxWrapperScrollable from 'components/BoxWrapperScrollable';
import TicketFile from 'router/tickets/TicketFile';

const MaintenanceTabs = (configuration, element) =>
  [
    {
      label: 'interventions',
      icon: 'files',
      count: 0,
      show: true,
      render: () => (
        <BoxWrapperScrollable>
          <TicketFile
            showFilters
            defaultFilters={{ preventifs: [element] }}
            lockedFilters={['preventifs']}
          />
        </BoxWrapperScrollable>
      ),
      class: 'tabNoPadding'
    }
  ].filter((e) => e.show);

export default MaintenanceTabs;
