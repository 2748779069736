import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export default function useNotifications() {
  const { t } = useTranslation();

  const success = useRef((successMessage) => {
    toast.success(t(successMessage || 'savedModifications'), { duration: 1000 });
  });

  const error = useRef((errorMessage) => {
    toast.error(t(errorMessage || 'errorOccured'), { duration: 5000, closeButton: true });
  });

  const loading = useRef((loadingMessage) => {
    const id = toast.loading(t(loadingMessage || 'loading'), { duration: Infinity }); // Toast persistant
    return id; // Retourner l'ID pour pouvoir le fermer plus tard
  });

  const dismiss = useRef((toastId) => {
    toast.dismiss(toastId); // Fermer un toast spécifique
  });

  return {
    success: success.current,
    error: error.current,
    loading: loading.current,
    dismiss: dismiss.current
  };
}
