import { Grid, Box } from '@material-ui/core';

import { useState, useEffect } from 'react';
import { useIsBelowMd } from 'hooks/useMQ';
import { useHistory } from 'react-router-dom';
import { useEntity } from 'contexts/entities/entityContext';

import EntityFormDialog from './EntityFormDialog';
import EntityView from './EntityView';
import Element from './Element';
import List from './List';

import useStyles from './Entity.styles';
import { useRole } from 'hooks/useRole';
import EntityCreate from './EntityCreate';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import EntityForm from 'layouts/entities/EntityForm';
import classNames from 'utils/classNames';

function Entity({ children, childrenId, childrenAuto, showList = true }) {
  const {
    translations,
    entity,
    picker,
    afterDialogClose,
    isDialog,
    scheduler,
    db,
    disableCreate,
    styleChildren,
    maintenances,
    form,
    list,
    afterDoubleClick,
    doubleClick,
    settings,
    openFormOnClick,
    fullscreen,
    disableGutters,
    page,
    calendar,
    trash,
    sublist,
    isListTable,
    selectedId,
    setSelectedId,
    enableForm,
    pickerCreate,
    timeline,
    filter,
    disableShadow,
    openFormEditOnClick,
    off,
    publicForm,
    on_close_form,
    is_open_form
  } = useEntity();

  const isBelowMd = useIsBelowMd();
  const history = useHistory();
  const role = useRole();

  const { t } = useTranslation();

  const [isOpenView, setIsOpenView] = useState(false);
  const [elementIdToEdit, setElementIdToEdit] = useState(null);

  useEffect(() => (isBelowMd || !page || list || isListTable) && setIsOpenView(!!selectedId), [selectedId]);

  useEffect(() => {
    if (childrenAuto) {
      if (childrenId) {
        setSelectedId(childrenId);
      } else {
        setSelectedId(null);
      }
    }
  }, [childrenId]);

  const classes = useStyles({
    trash,
    picker,
    disableShadow,
    disableGutters,
    filter,
    form,
    scheduler,
    settings,
    page,
    list,
    calendar,
    timeline,
    maintenances,
    fullscreen,
    off
  });

  if (publicForm)
    return (
      <EntityForm
        isPublic
        isCreate
      />
    );

  return (
    <>
      <EntityFormDialog
        open={is_open_form ? is_open_form : !!elementIdToEdit && (enableForm || !disableCreate)}
        element={db[elementIdToEdit] || elementIdToEdit}
        isCreate={!db[elementIdToEdit]}
        onClose={() => (on_close_form ? on_close_form() : setElementIdToEdit(false))}
      />

      {!trash &&
        !children &&
        !disableCreate &&
        ((entity === 'suppliers' && role.permission('stocks', 'create_supplier')) ||
          role.permission(entity, 'create')) && (
          <EntityCreate
            in={!elementIdToEdit && !isOpenView && (pickerCreate || !picker || scheduler || form) && !sublist}
            onClick={() => setElementIdToEdit(true)}
            label={t(translations.create)}
            icon={(className) => (
              <FAIcon
                collection="fal"
                icon="add"
                className={className}
              />
            )}
            fab={!page}
            size={!page && !settings ? 'small' : 'large'}
          />
        )}

      {!children && (
        <Grid
          container
          className="w-full h-full rounded-2xl"
          style={scheduler || maintenances ? { background: 'white' } : {}}
        >
          {Boolean(showList) && (
            <Grid
              item
              xs={12}
              md={picker || !page || list || off || isListTable ? 12 : 5}
              className={classNames(classes.list, 'listContained')}
            >
              <List setIsOpenForm={setElementIdToEdit} />
            </Grid>
          )}

          {!off && !isBelowMd && !picker && page && !list && !isListTable && (
            <Grid
              item
              md={showList && 7}
              className={!showList && 'w-full'}
            >
              <Element setIsOpenForm={setElementIdToEdit} />
            </Grid>
          )}
        </Grid>
      )}

      {!!children && (
        <Box
          style={styleChildren || { height: 'auto' }}
          {...(doubleClick
            ? {
                onDoubleClick: (e) => [
                  e.preventDefault(),
                  e.stopPropagation(),
                  setSelectedId(childrenId),
                  afterDoubleClick && afterDoubleClick()
                ]
              }
            : {
                onClick: (e) => [
                  openFormEditOnClick
                    ? setElementIdToEdit(childrenId)
                    : openFormOnClick
                    ? setElementIdToEdit(true)
                    : setSelectedId(childrenId),
                  e.preventDefault(),
                  e.stopPropagation()
                ]
              })}
        >
          {children}
        </Box>
      )}

      {!openFormOnClick && (isBelowMd || !page || list || isListTable) && (
        <EntityView
          setIsOpenForm={setElementIdToEdit}
          open={isOpenView}
          isDialog={isDialog}
          onClose={() => {
            if (!page) {
              setSelectedId(null);
              if (afterDialogClose) {
                afterDialogClose();
              }
            } else {
              history.push(history.location.pathname.replace('/' + selectedId, ''));
            }
          }}
        />
      )}
    </>
  );
}

export default Entity;
