import AccountingFile from './AccountingFile';

export const AccountingPage = () => {
  return (
    <div className="h-full w-full p-2">
      <AccountingFile
        showFilters
        showCustomFilters
        hiddenFilters={{ has_own_invoice: true }}
      />
    </div>
  );
};
