import { Box, Toolbar, InputBase, IconButton, Menu, Tooltip, Badge } from '@material-ui/core';
import useStyles from './SearchBar.styles';
import { useEntity } from 'contexts/entities/entityContext';
import EntityMenu from './EntityMenu';
import { useTranslation } from 'react-i18next';
import { Save, Tune, LayersClear } from '@material-ui/icons';
import { useState } from 'react';
import FilterSetList from './FilterSetList';
import { useAuth } from 'hooks/useAuth';
import { useForm } from 'frmx';

export default function SearchBar({
  newTextSearch,
  handleTextSearch,
  fetchElements,
  isLoading,
  getXhrFilters,
  setManageFilterBar,
  clearFilter,
  sets,
  set,
  newSet,
  setSets,
  setSet,
  buildFilters,
  setAppliedFilters,
  appliedFilters,
  setsKey
}) {
  const { listAction, filter, form, calendar, page, filtersSave, elements } = useEntity();
  const { resetForm } = useForm();

  const [menuFilterIsOpen, setMenuFilterIsOpen] = useState();

  const auth = useAuth();

  const openFilterMenu = (e) => setMenuFilterIsOpen(e.currentTarget);
  const closeFilterMenu = (e) => setMenuFilterIsOpen(null);

  const { t } = useTranslation();

  const classes = useStyles({ page, loading: isLoading, isList: !page, filter, form, calendar });
  const elementsLength = elements?.count || 0;

  return (
    <>
      <Menu
        transitionDuration={0}
        anchorEl={menuFilterIsOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={!!menuFilterIsOpen}
        onClose={closeFilterMenu}
        PaperProps={{ style: { width: 216 } }}
        MenuListProps={{ className: classes.moreMenu }}
        className={classes.moreMenu}
      >
        <FilterSetList
          activeIndex={set?.index}
          setSets={setSets}
          newSet={newSet}
          classes={classes}
          setManageFilterBar={setManageFilterBar}
          closeFilterMenu={closeFilterMenu}
          sets={sets}
          setAppliedFilters={setAppliedFilters}
          buildFilters={buildFilters}
          setsKey={setsKey}
          setSet={setSet}
        />
      </Menu>

      <Toolbar className={classes.searchBar}>
        {auth.interface.isPublic ? (
          <span style={{ marginRight: '1em' }} />
        ) : (
          <EntityMenu
            search={newTextSearch}
            fetchElements={fetchElements}
            getXhrFilters={getXhrFilters}
          />
        )}

        <InputBase
          autoFocus
          className={classes.searchInput}
          placeholder={
            !elementsLength ? t('searchEtc') : t('searchAmongNResults', { count: elementsLength })
          }
          value={newTextSearch}
          onChange={(e) => handleTextSearch(e.target.value)}
          style={{ minHeight: '3em' }}
        />

        {listAction && <Box>{listAction}</Box>}
        {!auth.interface.isPublic && (
          <Tooltip
            title={t('resetFilters')}
            placement="left"
          >
            <IconButton onClick={() => clearFilter({ resetForm })}>
              <LayersClear />
            </IconButton>
          </Tooltip>
        )}
        {(page || filtersSave) && (
          <Tooltip
            title={t('your-filters')}
            placement="left"
          >
            <IconButton onClick={(e) => openFilterMenu(e)}>
              <Badge
                badgeContent={set?.index > 0 ? String.fromCharCode(set?.index + 65) : 0}
                color="primary"
              >
                <Tune />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {(page || filtersSave) && (
          <Tooltip
            title={t('saveChanges')}
            placement="left"
          >
            <IconButton
              onClick={() => {
                const next = {
                  title: set.title,
                  filters: set.filters,
                  active: set.active,
                  index: set.index,
                  values: appliedFilters
                };
                const nextSets = sets.map((s) => (s.index === next.index ? next : s));
                setSet(next);
                setSets(nextSets);
                localStorage.setItem(setsKey, JSON.stringify(nextSets));
              }}
            >
              <Save />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </>
  );
}
