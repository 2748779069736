import { useRef, useEffect } from 'react';
import {
  Box,
  ListItem,
  Chip,
  ListItemIcon,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { NotificationImportant } from '@material-ui/icons';

import { isObject } from 'lodash-es';

import Avatar from 'components/Avatar';
import Manager from 'entities/Manager/Manager';
import Intervener from 'entities/Intervener/Intervener';
import Equipment from 'entities/Equipment/Equipment';
import Location from 'entities/Location/Location';
import Client from 'entities/Client/Client';

import { Phone, Send, Star, FileCopy } from '@material-ui/icons';
import { useIsBelowMd } from 'hooks/useMQ';

import { zones } from 'lists/zones';

import useStyles from 'layouts/entities/View.styles';
import { useState } from 'react';
import ViewItem from './ViewItem';
import GuaranteePreview, { isGuaranteeOn } from 'entities/Guarantee/GuaranteePreview';
import { isContractOn, endContract, startContract } from 'entities/Contract/ContractPreview';
import FAIcon from 'components/ui/FAIcon';
import Cursor from 'utils/cursor';
import useKeys from '@flowsn4ke/usekeys';
import Preview from 'layouts/entities/Preview';
import { contractStates, CONTRACT_CANCELED } from 'lists/contractStates';
import { dateToLocalFormat } from 'utils/dates';
import { useTranslation } from 'react-i18next';
import EntityBreadcrumbs from 'layouts/entities/EntityBreadcrumbs';
import useClipboard from 'hooks/useClipboard';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { getLocationName } from 'entities/Location/LocationPreview';
import classNames from 'utils/classNames';
import {
  critical_equipment,
  loss_ratio_equipment,
  LossRatioPopover,
  operational_states_equipment,
  OperationnalTooltip,
  replacement_date_planned,
  ReplacementTooltip,
  warranty_equipment,
  WarrantyTooltip
} from 'lists/advance_indicator_equipment';
import Tooltip from 'components/ToolTip';
import { useConfiguration } from 'hooks/useConfiguration';

export function PhoneItem({ element, fix, label }) {
  const phone = element[fix ? 'fix' : 'phone'];

  const [isHover, setIsHover] = useState(false);
  const { copy, copied } = useClipboard();

  const call = () => window.open(`tel: ${phone}`, '_self');

  const isBelowMd = useIsBelowMd();

  return (
    <Box
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
    >
      <ViewItem
        icon={fix ? 'phone-rotary' : 'phone'}
        secondaryIcon
        primary={label ? label : fix ? 'Fixe' : 'Portable'}
        secondary={
          <Box
            component="span"
            display="inline-flex"
            alignItems="center"
          >
            {formatPhoneNumber(phone)}
            {isHover && !isBelowMd && (
              <span style={{ display: 'inline-flex', height: 0, alignItems: 'center' }}>
                {
                  <IconButton
                    style={{ marginLeft: 6 }}
                    size={'small'}
                    onClick={() => call()}
                  >
                    <Phone style={{ fontSize: 13, width: 13, height: 13 }} />
                  </IconButton>
                }
                {
                  <IconButton
                    style={{ marginLeft: 6 }}
                    size={'small'}
                    onClick={() => copy(phone)}
                  >
                    <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
                  </IconButton>
                }
                {copied && (
                  <strong
                    style={{
                      color: '#27ae60',
                      fontSize: 11,
                      marginLeft: 6,
                      position: 'relative',
                      top: 2
                    }}
                  >
                    Téléphone copié dans le presse papier
                  </strong>
                )}
              </span>
            )}
          </Box>
        }
        onClick={() => isBelowMd && call()}
      />
    </Box>
  );
}

export const EmailItem = function ({ element }) {
  const email = element.email;
  const send = () => window.open(`mailto: ${email}`, '_self');
  const isBelowMd = useIsBelowMd();

  const [isHover, setIsHover] = useState(false);

  const { copy, copied } = useClipboard();

  return (
    <Box
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
    >
      <ViewItem
        icon="envelope"
        secondaryIcon
        primary="Email"
        secondary={
          <Box
            component="span"
            display="flex"
            alignItems="center"
          >
            {email}

            {isHover && !isBelowMd && (
              <span style={{ display: 'inline-flex', height: 0, alignItems: 'center' }}>
                <IconButton
                  style={{ marginLeft: 6 }}
                  size={'small'}
                  onClick={() => send()}
                >
                  <Send style={{ fontSize: 13, width: 13, height: 13 }} />
                </IconButton>
                <IconButton
                  style={{ marginLeft: 6 }}
                  size={'small'}
                  onClick={() => copy(email)}
                >
                  <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
                </IconButton>
                {copied && (
                  <strong
                    style={{
                      color: '#27ae60',
                      fontSize: 11,
                      marginLeft: 6,
                      position: 'relative',
                      top: 2
                    }}
                  >
                    Email copié dans le presse papier
                  </strong>
                )}
              </span>
            )}
          </Box>
        }
        onClick={() => isBelowMd && send()}
      />
    </Box>
  );
};

export const ZoneItem = function ({ element }) {
  const { t } = useTranslation();

  return element ? (
    <ViewItem
      icon="map-marker-alt"
      secondaryIcon
      primary={t('interventionZones')}
      secondary={
        element.zone_ids?.map((zone_id) => zones.find((z) => z.zone_id === zone_id)?.label || '').join(', ') || ''
      }
    />
  ) : null;
};

export const GuaranteeItem = function ({ guarantees = [], parentId, from }) {
  const showGuarantees = guarantees.filter((guarantee) => (guarantee._location || guarantee._equipment) === parentId);
  const activeGuarantees = showGuarantees.filter((guarantee) => isGuaranteeOn(guarantee, new Date(from)));

  const classes = useStyles();
  const k = useKeys();
  const { t } = useTranslation();

  return (
    !!activeGuarantees?.length && (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        position="right"
        title={
          <>
            {showGuarantees.map((guarantee, i) => (
              <Preview
                key={k(i)}
                element={guarantee}
                previewComponent={GuaranteePreview}
                stylePreview={{ backgroundColor: 'white', color: 'black' }}
                previewPayload={{ from }}
              />
            ))}
          </>
        }
      >
        <Box
          display={'inline-flex'}
          alignItems={'center'}
          className={classes.successColor}
          fontWeight={500}
        >
          <FAIcon
            style={{ marginRight: 4, fontSize: 12 }}
            collection={'fad'}
            icon={'shield-halved'}
          />
          {t('underWarranty', { count: activeGuarantees?.length })}
        </Box>
      </Tooltip>
    )
  );
};

export const ContractItem = function ({ contracts = [] }) {
  const activeContracts = contracts.filter((contract) => isContractOn(contract));

  const classes = useStyles();
  const k = useKeys();

  return (
    !!activeContracts?.length && (
      <Tooltip
        position="right"
        title={
          <>
            {activeContracts.map((contract, i) => (
              <Box key={k(i)}>
                {contract.name} <br />
                Commence le {startContract(contract)} <br />
                Expire le {endContract(contract)} <br />
                {i !== activeContracts?.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </Box>
            ))}
          </>
        }
      >
        <Box
          display={'inline-flex'}
          alignItems={'center'}
          className={classes.successColor}
          fontWeight={500}
        >
          <FAIcon
            style={{ marginRight: 4, fontSize: 10 }}
            collection={'fas'}
            icon={'file-contract'}
          />
          {`Sous contrat (${activeContracts?.length})`}
        </Box>
      </Tooltip>
    )
  );
};

export const LocationItem = function ({ location, guarantees = [], actions, guaranteeFrom, noType }) {
  const [isHoverLocation, setIsHoverLocation] = useState(false);

  //! DO NOT FUCKING TOUCH @BLACHERE
  const field1Blach = location?.fields?.find((f) => f._field === '5e0f9516754a8b004bc10c21');
  const field2Blach = location?.fields?.find((f) => f._field === '5e131a2cab86b1004ac6ea80');

  const [itemId, setItemId] = useState(null);

  return location ? (
    <Box
      onMouseEnter={(e) => setIsHoverLocation(true)}
      onMouseLeave={(e) => setIsHoverLocation(false)}
    >
      <Location
        childrenId={itemId}
        childrenAuto
      >
        <ViewItem
          button
          icon="map-marker-alt"
          secondaryIcon
          onClick={() => setItemId(location._id)}
          primary={
            <div className="flex items-center gap-1.5">
              <EntityBreadcrumbs
                setItemId={setItemId}
                inline
                element={location}
                sublistParentKey={'_parentLocations'}
              />

              {isHoverLocation && !noType && location._type && (
                <Chip
                  size={'small'}
                  label={location._type.name}
                  className="font-normal"
                />
              )}
            </div>
          }
          secondary={
            <Box
              component="span"
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component="span">{getLocationName(location)}</Box>
              <GuaranteeItem
                guarantees={guarantees}
                parentId={location._id}
                from={guaranteeFrom}
              />
              {!!field1Blach && (
                <>
                  <br />
                  {field1Blach.value}
                </>
              )}
              {!!field2Blach && (
                <>
                  <br />
                  {field2Blach.value}
                </>
              )}

              <PracticalData location={location} />
            </Box>
          }
          actions={isHoverLocation && actions}
        />
      </Location>
    </Box>
  ) : null;
};

export const PracticalData = function ({ location }) {
  const { t } = useTranslation();

  const max_length = 70;

  return location?.informations ? (
    <Box className="mt-3 flex flex-col text-gray-600">
      <strong>{t('informations')}</strong>
      <span>
        {location.informations.length > max_length
          ? `${location.informations.slice(0, max_length)}...`
          : location.informations}
      </span>
    </Box>
  ) : null;
};

export const EquipmentItem = function ({ equipment, guarantees = [] }) {
  const [isHoverEquipment, setIsHoverEquipment] = useState(false);
  const configuration = useConfiguration();
  const [itemId, setItemId] = useState(null);
  const { t } = useTranslation();

  const operational_state = equipment?.operational_state || 'NOT_SPECIFIED';
  const replacement_date = equipment?.replacement_date || 'NOT_SPECIFIED';

  const warranties = guarantees || 'NOT_SPECIFIED';

  const operational = operational_states_equipment.find((e) => String(e.value) === String(operational_state));
  const replacement_planned = replacement_date_planned(replacement_date, operational_state, equipment.status);
  const criticity = critical_equipment(equipment?.criticity);
  const warranty = warranty_equipment(warranties);
  const tickets = equipment?._tickets;
  const loss_ratio = loss_ratio_equipment(tickets);

  return equipment ? (
    <Box
      onMouseEnter={(e) => setIsHoverEquipment(true)}
      onMouseLeave={(e) => setIsHoverEquipment(false)}
    >
      <Equipment
        childrenId={itemId}
        childrenAuto
      >
        <ViewItem
          button
          icon="wrench-simple"
          secondaryIcon
          onClick={() => setItemId(equipment._id)}
          primary={
            <div className="w-full flex justify-between pr-4 lg:pr-0">
              <div className="flex gap-1.5 items-center">
                <EntityBreadcrumbs
                  setItemId={setItemId}
                  inline
                  element={equipment}
                  sublistParentKey={'_parentEquipments'}
                />
                {!!criticity && configuration.isClient && (
                  <Tooltip
                    title={t(criticity.label)}
                    position="bottom"
                  >
                    <div>
                      <FAIcon
                        icon={'chevrons-up'}
                        collection={'fad'}
                        size="medium"
                        className={`hover:${criticity.hoverColor} ${criticity.color} rounded-full`}
                      />
                    </div>
                  </Tooltip>
                )}
                {isHoverEquipment && equipment._category && (
                  <Chip
                    size={'small'}
                    label={equipment._category.name}
                    className="font-normal"
                  />
                )}
                {isHoverEquipment && equipment._subcategory && (
                  <Chip
                    size={'small'}
                    label={equipment._subcategory.name}
                    className="font-normal"
                  />
                )}
              </div>

              {/* IAE */}
              <div className="flex flex-wrap gap-4 items-center">
                <Tooltip
                  title={<OperationnalTooltip operational={operational} />}
                  position="bottom"
                >
                  <div>
                    <FAIcon
                      icon={operational.icon}
                      collection="fas"
                      size={'medium'}
                      className={`hover:${operational.hoverColor} ${operational.color} rounded-full`}
                    />
                  </div>
                </Tooltip>
                {configuration.isClient && (
                  <>
                    <Tooltip
                      title={<WarrantyTooltip warranty={warranty} />}
                      position="bottom"
                    >
                      <div>
                        <FAIcon
                          icon={warranty.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${warranty.hoverColor} ${warranty.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={
                        <LossRatioPopover
                          loss_ratio={loss_ratio}
                          interventions={tickets}
                        />
                      }
                      position="bottom"
                    >
                      <div>
                        <FAIcon
                          icon={loss_ratio.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${loss_ratio.hoverColor} ${loss_ratio.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={
                        <ReplacementTooltip
                          replacement_planned={replacement_planned}
                          replacement_date={replacement_date}
                        />
                      }
                      position="bottom"
                    >
                      <div>
                        <FAIcon
                          icon={replacement_planned.icon}
                          collection="fad"
                          size={'medium'}
                          className={`hover:${replacement_planned.hoverColor} ${replacement_planned.color} rounded-full`}
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          }
        />
      </Equipment>
    </Box>
  ) : null;
};

export const ProvionalDateItem = ({ equipment }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return equipment?.status === 'PLAN_REPLACEMENT' ? (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      position="right"
      title={
        <div className="bg-white text-gray-700 p-3 flex flex-col gap-2 text-sm">
          <div>{t('planned-replacement')}</div>
          <div className="flex items-center justify-center gap-2 text-xs">
            <FAIcon
              collection="fas"
              icon="calendar"
              className="text-orange-500"
              size="small"
            />
            {equipment?.replacement_date ? (
              <>{dateToLocalFormat(equipment?.replacement_date, 'PPP')}</>
            ) : (
              <>{t('no-date-provided')}</>
            )}
          </div>
        </div>
      }
    >
      <Box className="flex items-center justify-center gap-1 text-orange-500">
        <FAIcon
          collection="fas"
          icon="calendar"
          size="small"
        />
        <span className="text-xs">{t('plan-replacement')}</span>
      </Box>
    </Tooltip>
  ) : null;
};

export const ManagerItem = function ({ user }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return user ? (
    <Box>
      <Manager
        styleChildren={{ height: 'auto' }}
        childrenId={user._id}
      >
        <ListItem
          dense
          button
          className={classes.listItem}
        >
          <ListItemIcon>
            <Avatar
              round
              className={classNames(classes.listIcon, classes.listAvatar)}
              isUser
              entity={user}
              shadow={false}
              size={'xsmall'}
            />
          </ListItemIcon>
          <ListItemText
            primary={<strong>{t('seniorManager')}</strong>}
            secondary={user.lastName + ' ' + user.firstName}
          />
        </ListItem>
      </Manager>
    </Box>
  ) : null;
};

export const ClientItem = function ({ client, primary, secondary, actions, actionsVisible }) {
  const name = client?.regime === 'pro' ? client?.name : client?.firstName + ' ' + client?.lastName;
  const { t } = useTranslation();
  const classes = useStyles();

  return client ? (
    <Box className={classes.listItemSecondary}>
      <Client childrenId={client._id}>
        <ListItem
          dense
          button
          className={classes.listItem}
        >
          <ListItemIcon>
            <Avatar
              className={classNames(classes.listIcon, classes.listAvatar)}
              isUser={client.regime !== 'pro'}
              entity={client}
              shadow={true}
              size={'small3'}
            />
          </ListItemIcon>
          <ListItemText
            primary={<strong>{primary || t('client')}</strong>}
            secondary={secondary || name}
          />
          {
            <ListItemSecondaryAction
              className={classNames(classes.listItemSecondary, classes.listItemSecondaryMenu)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!!actions && <Box className="hover">{actions}</Box>}
              {!!actionsVisible && <Box>{actionsVisible}</Box>}
            </ListItemSecondaryAction>
          }
        </ListItem>
      </Client>
    </Box>
  ) : (
    <ListItem
      dense
      button
      className={classes.listItem}
    >
      <ListItemIcon>
        <Skeleton
          animation="wave"
          variant="circle"
          width={32}
          height={32}
          style={{ marginRight: 10, borderRadius: '8px' }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Skeleton
            animation="wave"
            variant="text"
            width={42}
            height={12}
          />
        }
        secondary={
          <Skeleton
            animation="wave"
            variant="text"
            width={64}
            height={10}
          />
        }
      />
      {
        <ListItemSecondaryAction
          className={classNames(classes.listItemSecondary, classes.listItemSecondaryMenu)}
          style={{ display: 'flex', alignItems: 'center' }}
        ></ListItemSecondaryAction>
      }
    </ListItem>
  );
};

const RatingItem = function ({ rating }) {
  return (
    <Box
      component={'span'}
      display={'flex'}
      alignItems={'center'}
      marginLeft={'10px'}
    >
      <strong style={{ color: '#fbb034', fontSize: 11, position: 'relative', top: 1 }}>{rating}</strong>{' '}
      <Star style={{ color: '#fbb034', fontSize: 14, height: 14, width: 14 }} />
    </Box>
  );
};

export const ContractorItem = function ({
  contractor,
  primary,
  secondary,
  actions,
  rating,
  actionsVisible,
  contracts = [],
  relances
}) {
  const name = contractor?.name || contractor?.companyName;

  const { t } = useTranslation();

  const relancesTypes = {
    date: {
      icon: 'calendar',
      label: 'dateReminder'
    },
    devis: {
      icon: 'file-search',
      label: 'quoteReminder'
    },
    facture: {
      icon: 'file-invoice',
      label: 'invoiceReminder'
    },
    update: {
      icon: 'info-circle',
      label: 'informationReminder'
    }
  };

  const k = useKeys();

  const classes = useStyles();
  return isObject(contractor) ? (
    <Box className={classes.listItemSecondary}>
      <Intervener
        type={'contractor'}
        childrenId={contractor._id}
      >
        <ListItem
          dense
          button
          className={classes.listItem}
        >
          <ListItemIcon>
            <Avatar
              className={classNames(classes.listIcon, classes.listAvatar)}
              entity={{
                ...contractor,
                name
              }}
              shadow={true}
              size={'small3'}
            />
          </ListItemIcon>
          <ListItemText
            primary={<strong>{secondary || name}</strong>}
            secondary={
              <Box
                component="span"
                display="flex"
                flexDirection={'column'}
                position={'relative'}
              >
                {primary || t('intervener')}
                {!!rating && <RatingItem rating={rating.rate} />}
                {<ContractItem contracts={contracts} />}
              </Box>
            }
          />
          <ListItemSecondaryAction
            className={classNames(classes.listItemSecondary, classes.listItemSecondaryMenu)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {!!actions && <Box className="hover">{actions}</Box>}
            {!!relances?.length && (
              <Tooltip
                title={
                  <>
                    <div style={{ fontSize: 14, marginBottom: 6 }}>
                      {t('intervenerRemindedXTimes', { count: relances?.length })}
                    </div>
                    {relances.map((el, i) => (
                      <div
                        key={k(i)}
                        style={{
                          width: '100%',
                          backgroundColor: 'white',
                          opacity: 0.97,
                          color: '#000000',
                          borderRadius: 4,
                          padding: 4,
                          paddingBottom: 2,
                          marginBottom: 6,
                          fontSize: 12,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <FAIcon
                          size="small"
                          collection="fas"
                          icon={relancesTypes[el.type].icon}
                        />
                        <Typography
                          variant="body2"
                          component="span"
                          style={{ marginLeft: 4 }}
                        >
                          {t(relancesTypes[el.type].label)}{' '}
                          {t('onDateX', { date: dateToLocalFormat(el.date, 'PPPPp') })}
                        </Typography>
                      </div>
                    ))}
                  </>
                }
                position="top"
              >
                <IconButton
                  style={{ marginLeft: 4 }}
                  onClick={(e) => [e.preventDefault(), e.stopPropagation()]}
                >
                  <Badge
                    badgeContent={relances?.length || 0}
                    color="primary"
                  >
                    <NotificationImportant />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            {!!actionsVisible && <Box>{actionsVisible}</Box>}
          </ListItemSecondaryAction>
        </ListItem>
      </Intervener>
    </Box>
  ) : (
    <ListItem
      dense
      button
      className={classes.listItem}
    >
      <ListItemIcon>
        <Skeleton
          animation="wave"
          variant="circle"
          width={32}
          height={32}
          style={{ marginRight: 10, borderRadius: '8px' }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Skeleton
            animation="wave"
            variant="text"
            width={42}
            height={12}
          />
        }
        secondary={
          <Skeleton
            animation="wave"
            variant="text"
            width={64}
            height={10}
          />
        }
      />
    </ListItem>
  );
};

export const TechnicianItem = function ({ rating, technician, primary, secondary, actions, actionsVisible, relances }) {
  const name = technician?.firstName + ' ' + technician?.lastName;

  const classes = useStyles();
  return isObject(technician) ? (
    <Box className={classes.listItemSecondary}>
      <Intervener
        type={'technician'}
        childrenId={technician._id}
      >
        <ListItem
          dense
          button
          className={classes.listItem}
        >
          <ListItemIcon>
            <Avatar
              className={classNames(classes.listIcon, classes.listAvatar)}
              isUser
              round
              entity={{
                name: '',
                avatar: technician._user?.avatar
              }}
              shadow={true}
              size={'small3'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <strong style={{ display: 'inline-flex', alignItems: 'center' }}>
                {secondary || name}
                {!!rating && <RatingItem rating={rating.rate} />}
              </strong>
            }
            secondary={technician._role?.name || 'Technicien'}
          />
          {
            <ListItemSecondaryAction
              className={classNames(classes.listItemSecondary, classes.listItemSecondaryMenu)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {!!actions && <Box className="hover">{actions}</Box>}
              {!!actionsVisible && <Box>{actionsVisible}</Box>}
            </ListItemSecondaryAction>
          }
        </ListItem>
      </Intervener>
    </Box>
  ) : (
    <ListItem
      dense
      button
      className={classes.listItem}
    >
      <ListItemIcon>
        <Skeleton
          animation="wave"
          variant="circle"
          width={32}
          height={32}
          style={{ marginRight: 10, borderRadius: '50%' }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Skeleton
            animation="wave"
            variant="text"
            width={42}
            height={12}
          />
        }
        secondary={
          <Skeleton
            animation="wave"
            variant="text"
            width={64}
            height={10}
          />
        }
      />
    </ListItem>
  );
};

export const DescriptionItem = function ({ description, icon, canUpdate = false, onUpdate }) {
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(description);

  const descriptionBox = useRef(null);

  useEffect(() => {
    setText(description);
    setIsEdit(false);

    if (descriptionBox.current) {
      descriptionBox.current.innerText = description;
    }
  }, [description]);

  const focus = () => {
    if (descriptionBox.current) {
      setTimeout(() => {
        const offset = Cursor.getCurrentCursorPosition(descriptionBox.current);
        Cursor.setCurrentCursorPosition(offset, descriptionBox.current);
        descriptionBox?.current?.focus();
      }, 100);
    }
  };

  const save = (e) => [setIsEdit(false), onUpdate(text), e.stopPropagation(), e.preventDefault()];
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);

  return !!description?.length ? (
    <Box
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
    >
      <ViewItem
        onClick={() => [canUpdate && setIsEdit(true), focus()]}
        style={{ cursor: 'pointer' }}
        {...(isEdit || isHover
          ? {
              icon: 'pencil'
            }
          : {
              icon: 'quote-left'
            })}
        secondaryIcon
        collection="fas"
        secondary={
          <Box
            ref={descriptionBox}
            onInput={(e) => setText(e.target.innerText)}
            onBlur={save}
            className={classes.contentEditable}
            contentEditable={isEdit}
            style={
              isEdit
                ? { padding: 10, position: 'relative', right: 10, whiteSpace: 'pre-wrap' }
                : { whiteSpace: 'pre-wrap' }
            }
          ></Box>
        }
      />
    </Box>
  ) : null;
};

export function LastConnectItem({ lastConnect }) {
  return (
    <ViewItem
      icon="clock"
      secondary={'Dernière connexion le ' + dateToLocalFormat(lastConnect, 'PPPp')}
    />
  );
}

export const ReasonItem = function ({ reason }) {
  return reason?.length ? (
    <ViewItem
      icon="ban"
      collection="fal"
      secondary={<strong style={{ color: contractStates[CONTRACT_CANCELED].color }}>{reason}</strong>}
    />
  ) : null;
};
