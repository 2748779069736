import { useField, useFieldObserver, useForm } from 'frmx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Popover as MuiPopover } from '@material-ui/core';

export default function EntityOptions({ path, label, entity: Entity, entityProps, initialValue, isLock }) {
  const { t } = useTranslation();
  const { handleSubmit, hasUpdates } = useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [is_open_popover_filter, set_is_open_popover_filter] = useState(false);

  const { setValue } = useField(path);
  const value = useFieldObserver(path);

  const isModified = JSON.stringify(value) !== JSON.stringify(initialValue) && value !== undefined;

  const handle_click = (event) => {
    event.stopPropagation();
    if (isLock) return;
    set_is_open_popover_filter(!is_open_popover_filter);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button
        onClick={handle_click}
        className={`relative ${isLock ? 'text-gray-300 cursor-not-allowed bg-gray-50' : 'hover:bg-gray-50 text-gray-500 cursor-pointer transition'} border border-gray-300 inline-flex items-center px-3 h-8 rounded-full text-xs font-medium`}
      >
        {/* add indicator when value is not initial value */}
        {isModified && (
          <svg
            className="absolute -top-1 -right-1 w-3 h-3"
            viewBox="0 0 10 10"
          >
            <circle
              cx="5"
              cy="5"
              r="5"
              className="fill-green-500"
            />
          </svg>
        )}
        {/* Indicateur si modifié */}
        <span className="whitespace-nowrap">{t(label)}</span>
      </button>
      <MuiPopover
        open={is_open_popover_filter}
        transitionDuration={250}
        anchorEl={anchorEl}
        onClose={() => {
          set_is_open_popover_filter(!is_open_popover_filter);
          if (hasUpdates()) handleSubmit();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -120
        }}
      >
        <div className="z-40 h-full w-[30vw] min-w-[400px] max-w-[500px] p-5">
          <p className="text-sm">{t(label)}</p>
          <Entity {...entityProps} />

          <div className="flex justify-end items-center p-1 bg-white border-t -m-5 mt-3">
            <Button
              size="small"
              onClick={() => {
                setValue(initialValue);
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('erase')}
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('validate')}
            </Button>
          </div>
        </div>
      </MuiPopover>
    </>
  );
}
