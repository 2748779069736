const StockExport = (configuration) =>
  [
    { label: 'name', key: 'name', show: true },
    { label: 'reference', key: 'referenceMaker', show: true },
    // { label: 'comments', key: 'comments', show: true },
    { label: 'Stock', key: 'stock', show: true },
    { label: 'alertThreshold', key: 'orderStock', show: true },
    { label: 'targetStock', key: 'targetStock', show: true },
    { label: 'unitValuationProduct', key: 'unitValuationPrice', show: true },
    { label: 'totalValuationPrice', key: 'totalValuationPrice', show: true },
    { label: 'suppliers', key: '_suppliers', show: true },
    { label: 'managers', key: '_managers', show: true },
    { label: 'locations', key: '_locations', show: true },
    { label: 'equipmentsTitle', key: '_equipments', show: true },
    { label: 'supplierItemCode', key: 'supplierItemCode', show: true },
    { label: 'storageLocation', key: '_warehouse', show: true }
  ].filter((e) => e.show);

export default StockExport;
