import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import FAIcon from 'components/ui/FAIcon';
import isMobile from 'utils/isMobile';
import { trimBeforeEnd } from 'utils/trim';
import { DIR_MIME_TYPE } from './constants';
import getFileIconFromMimeType from './fileIcons';
import ImageWithRetryFallback from 'components/ImageWithRetryFallback';

export default function Document({
  document,
  read_only_mode,
  idx,
  openFile,
  moveMultipleDocuments,
  goToFolder,
  setContextMenuPosition,
  setSelectedDocuments,
  selectedDocuments
}) {
  const [isDropTarget, setIsDropTarget] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const isMobileDevice = isMobile();

  // miniature name for images files
  const miniatureName = (key) => {
    const [fileName] = key.split('.');
    return `${fileName}_min.webp`;
  };

  // miniature name for other types of previewable files like .pdf files
  const fileMiniatureName = (key) => {
    const [fileName] = key.split('.');
    return `${fileName}_preview_min.webp`;
  };

  const fileDisplayName = useMemo(() => {
    if (!document.name) {
      return '';
    }

    if (document.mimetype === DIR_MIME_TYPE) {
      return document.name;
    }

    return trimBeforeEnd(document.name, 12);
  }, [document.name]);

  const previewUrl = useMemo(() => {
    if (document.mimetype === DIR_MIME_TYPE) {
      return null;
    }

    // list of all mimetypes available here : https://mimetype.io/all-types
    const imageMimeTypesViewableInBrowser = [
      'image/png',
      'image/jpeg',
      'image/gif',
      'image/webp',
      'image/svg+xml',
      'image/tiff',
      'image/avif',
      'image/webp',
      'image/heic',
      'image/bmp'
    ];
    const fileMimeTypesViewableInBrowser = ['application/pdf'];

    if (imageMimeTypesViewableInBrowser.includes(document.mimetype)) {
      return process.env.REACT_APP_S3_IMAGES_BUCKET + miniatureName(document.s3_key);
    }

    if (fileMimeTypesViewableInBrowser.includes(document.mimetype)) {
      return process.env.REACT_APP_S3_IMAGES_BUCKET + fileMiniatureName(document.s3_key);
    }

    return document?.previews?.[0]?.url;
  }, [document.mimetype]);

  const icon = useMemo(() => {
    return document.mimetype === DIR_MIME_TYPE && isDropTarget
      ? 'folder-open'
      : getFileIconFromMimeType(document.mimetype);
  }, [document.mimetype, isDropTarget]);

  const openDocument = () => {
    if (document.mimetype === DIR_MIME_TYPE) {
      goToFolder(document);
    } else {
      openFile(idx);
    }
  };

  const setSelectedDocument = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // If i press ctrl or cmd key, add or remove the document from the selection
    if (event.ctrlKey || event.metaKey) {
      setSelectedDocuments(
        selectedDocuments.some((doc) => doc._id === document._id)
          ? selectedDocuments.filter((doc) => doc._id !== document._id)
          : [...selectedDocuments, document]
      );
    } else {
      setSelectedDocuments([document]);
    }
  };

  const openContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const allDocuments = selectedDocuments.some((doc) => doc._id === document._id)
      ? selectedDocuments
      : [...selectedDocuments, document];
    // add to setSelectedIds if is not
    setSelectedDocuments(allDocuments);
    setContextMenuPosition({ top: event.clientY, left: event.clientX, documents: allDocuments });
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (document.mimetype === DIR_MIME_TYPE && !isDragging) {
      setIsDropTarget(true);
    }
  };

  const handleDragLeave = (event) => {
    event.stopPropagation();
    setIsDropTarget(false);
  };

  const handleDragStart = (event) => {
    event.stopPropagation();
    const dataToSend = selectedDocuments.length > 1 ? selectedDocuments.map((doc) => doc._id) : document._id;
    event.dataTransfer.setData('_ids', dataToSend);
    event.target.classList.add('opacity-50');
    setIsDragging(true);
  };

  const handleDragEnd = (event) => {
    event.stopPropagation();
    event.target.classList.remove('opacity-50');
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsDropTarget(false);
    const _ids = event.dataTransfer.getData('_ids').split(',');

    if (document.mimetype === DIR_MIME_TYPE) {
      const documentsToMove = _ids.map((id) => ({ document_id: id, parent_id: document._id }));
      moveMultipleDocuments(documentsToMove);
    }
  };

  return (
    <div
      id={`document-${idx}`}
      className={`select-none document-item flex flex-col items-center text-center m-2 hover:bg-white hover:bg-opacity-50 p-1.5 rounded-lg ${
        !read_only_mode ? 'cursor-pointer' : ''
      }`}
      onClick={isMobileDevice ? openDocument : setSelectedDocument}
      onDoubleClick={openDocument}
      onContextMenu={openContextMenu}
      draggable={!read_only_mode}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(event) => event.preventDefault()}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}
    >
      <div className={classNames('w-[88px] h-[88px] flex flex-col items-center text-black text-xl font-black rounded')}>
        {previewUrl ? (
          <ImageWithRetryFallback
            previewUrl={previewUrl}
            fileDisplayName={fileDisplayName}
            mimetype={document.mimetype}
            isDropTarget={isDropTarget}
            isSelected={selectedDocuments.some((doc) => doc._id === document._id)}
          />
        ) : (
          <FAIcon
            draggable="false"
            collection="fad"
            icon={icon}
            className={classNames(
              'pointer-events-none relative top-2',
              isDropTarget && document.mimetype === DIR_MIME_TYPE
                ? 'text-green-500'
                : selectedDocuments.some((doc) => doc._id === document._id)
                ? 'text-sky-500'
                : 'text-slate-500'
            )}
            size="3xl"
          />
        )}
      </div>
      <p
        className={classNames(
          'break-words text-xs rounded px-1 py-0.5 mt-0.5',
          isDropTarget && document.mimetype === DIR_MIME_TYPE
            ? 'bg-green-500 text-white'
            : selectedDocuments.some((doc) => doc._id === document._id)
            ? 'bg-sky-500 text-white'
            : ''
        )}
      >
        {fileDisplayName}
      </p>
    </div>
  );
}
