import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import UploadButton from '../UploadButton';

export default function UploadVersionMenuItem({ onUpload, label, multiple = false }) {
  const { t } = useTranslation();
  return (
    <UploadButton
      onUpload={onUpload}
      multiple={multiple}
    >
      <div
        onContextMenu={(event) => event.preventDefault()}
        className="rounded hover:bg-gray-100 flex cursor-pointer items-center py-2 pr-8"
      >
        <FAIcon
          collection="fas"
          icon="file-plus"
          size="small"
          className="mx-1"
        />
        <span className="ml-1 text-sm ">{t(label)}</span>
      </div>
    </UploadButton>
  );
}
