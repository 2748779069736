import { useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import useKeys from '@flowsn4ke/usekeys';
import classNames from 'utils/classNames';
import FAIcon from 'components/ui/FAIcon';
import { useField } from 'frmx';
import TagEditor from './dialogs/TagEditor';
import { pick } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function TagPicker({ disabled, options, path }) {
  const { t } = useTranslation();
  const dropDownRef = useRef();
  const buttonRef = useRef();

  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const { value: elements, setValue: setElements } = useField(path);
  const k = useKeys();

  useOutsideClick(dropDownRef, () => setDropDownIsOpen(false));

  const isSelected = (element) => elements.find((el) => el._id === element._id);

  return (
    <div className=" relative">
      <div className="mb-1 flex items-center justify-between">
        <label className="block mb-1 text-sm font-medium text-gray-700">{t('tagsTitle')}</label>
        <TagEditor />
      </div>
      <button
        ref={buttonRef}
        onMouseDown={(event) => event.stopPropagation()}
        onClick={() => setDropDownIsOpen(dropDownIsOpen ? false : true)}
        disabled={disabled || !options?.length}
        className={classNames(
          'border border-gray-300 h-14 w-full disabled:cursor-default rounded-md py-2 px-3 flex justify-between items-center text-base'
        )}
      >
        <div className="w-full text-left">
          {elements
            .map((el) => el.label)
            .slice(0, 6)
            .join(', ') + (elements.length > 5 ? '...' : '')}
        </div>
        <FAIcon
          collection="fal"
          icon="sort"
          className="text-gray-400 pointer-events-none"
        />
      </button>
      <div className="relative w-full bg-white">
        <div
          ref={dropDownRef}
          className={classNames(
            dropDownIsOpen ? '' : 'hidden',
            'z-10 top-1 left-0 rounded border bg-white border-gray-300 w-full h-auto absolute flex flex-col text-center space-y-1'
          )}
        >
          {/* TODO: Add delete options yet present in the doc tags? at least so it can be unselected? */}
          {options?.map((option, i) => (
            <button
              onClick={(event) => {
                event.stopPropagation();
                if (isSelected(option)) {
                  setElements((elements) => elements.filter((el) => el._id !== option._id));
                } else {
                  setElements((elements) => [...elements, pick(option, ['_id', 'label', 'color'])]);
                }
              }}
              key={k(i)}
              className="flex h-8 items-center text-base font-normal hover:font-medium text-gray-900 border-gray-300 hover:bg-slate-50"
            >
              <div className="w-12 flex items-center justify-center text-center">
                <FAIcon
                  collection="fal"
                  // icon="check"
                  icon={isSelected(option) ? 'square-check' : 'square'}
                  className={classNames(
                    'mx-0.5',
                    isSelected(option) ? ' text-slate-400' : 'text-slate-200'
                  )}
                />
              </div>
              {option.color && (
                <span
                  className="w-2 h-2 rounded-full mr-2"
                  style={{ backgroundColor: option.color }}
                />
              )}
              {t(option.label)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
