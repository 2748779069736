import { Tooltip, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import FAIcon from 'components/ui/FAIcon.js';
import { useField } from 'frmx';
import { useConfiguration } from 'hooks/useConfiguration';
import { blachDomains, blachSubDomains } from 'lists/blachere';
import { contractStates } from 'lists/contractStates.js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateText } from 'utils/uiUtils';
import { statesClosed, statesOngoing } from './utils';

function FilterInput({ textFilter, setTextFilter, resetSelection }) {
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 flex items-center h-10 w-full bg-white my-auto shadow rounded-t px-1 z-10">
      <FAIcon
        className="mx-2"
        collection="far"
        icon="magnifying-glass"
        size="small"
      />
      <input
        autoFocus
        className="block w-full sm:text-sm md:text-md outline-none"
        placeholder={t('search')}
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
      />
      <IconButton onClick={resetSelection}>
        <FAIcon
          collection="far"
          icon="circle-xmark"
          size="small"
        />
      </IconButton>
    </div>
  );
}

function OptionButton({ option, isSelected, toggleSelection }) {
  const truncateSize = 40;
  const isTruncated = truncateSize < option.label.length;

  return (
    <Tooltip
      title={isTruncated ? option.label : ''}
      placement="right"
      disableHoverListener={!isTruncated}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          toggleSelection(option.value);
        }}
        className={classNames(
          'flex w-full h-9 items-center text-sm font-normal text-gray-900 hover:bg-gray-50',
          isSelected ? 'bg-gray-100' : ''
        )}
      >
        <div className="w-12 flex items-center justify-center text-center">
          <Checkbox
            style={option.color ? { color: option.color} : {}}
            color="primary"
            checked={isSelected}
          />
        </div>
        {truncateText(option.label, truncateSize)}
      </button>
    </Tooltip>
  );
}
function PickerComponent({ fieldKey, optionList }) {
  const { value: elements, setValue: setElements } = useField(fieldKey);
  const { setValue: setOngoing } = useField('ongoing');
  const { setValue: setClosed } = useField('closed');
  const { t } = useTranslation();
  const [textFilter, setTextFilter] = useState('');

  const options = useMemo(() => {
    const filteredOptions = optionList.filter((item) => item.label.toLowerCase().includes(textFilter.toLowerCase()));

    return filteredOptions.length ? filteredOptions : [{ label: t('noElementWasFound'), value: 'noResults' }];
  }, [textFilter, optionList]);

  const resetSelection = () => {
    setElements([]);
    setTextFilter('');

    if (fieldKey === 'status') {
      setOngoing(false);
      setClosed(false);
    }
  };

  const toggleSelection = (value) => {
    const newElements = elements.includes(value) ? elements.filter((el) => el !== value) : [...elements, value];

    if (fieldKey === 'status') {
      // Vérifie si tous les éléments de statesOngoing et statesClosed sont dans newElements
      setOngoing(statesOngoing.every((el) => newElements.includes(el)));
      setClosed(statesClosed.every((el) => newElements.includes(el)));
    }
    setElements(newElements);
  };

  return (
    <div className="relative rounded-2xl">
      <FilterInput
        textFilter={textFilter}
        setTextFilter={setTextFilter}
        resetSelection={resetSelection}
      />
      <div className="py-1">
        {options.map((option, i) =>
          option.value === 'noResults' ? (
            <div
              className="flex items-center justify-center p-2"
              key={i}
            >
              <span className="sm:text-sm md:text-md text-center text-gray-700">{option.label}</span>
            </div>
          ) : (
            <OptionButton
              key={i}
              option={option}
              isSelected={elements.includes(option.value)}
              toggleSelection={toggleSelection}
            />
          )
        )}
      </div>
    </div>
  );
}

// Wrapping components with unique configurations
export function StatesPicker({ path }) {
  const { t } = useTranslation();
  const contractOptions = Object.keys(contractStates).map((s) => ({
    label: t(contractStates[s].label),
    value: s,
    color: contractStates[s].color
  }));

  return (
    <PickerComponent
      fieldKey={path}
      optionList={contractOptions}
    />
  );
}

// Domains or sub domains Blachere:
export function DomainsPicker({ path }) {
  const config = useConfiguration();
  const domainOptions = config.isBlachere
    ? blachDomains
    : config.job_domains.map((d) => ({ label: d.label, value: d._id })) || [];

  return (
    <PickerComponent
      fieldKey={path}
      optionList={domainOptions}
    />
  );
}

export function SubDomainsPicker({ path }) {
  return (
    <PickerComponent
      fieldKey={path}
      optionList={blachSubDomains}
    />
  );
}

export function PrioritiesPicker() {
  const config = useConfiguration();
  const urgencyOptions = config?.urgencies
    ? Object.keys(config.urgencies)
        .map((key) => ({ label: config.urgencies[key].label, value: key }))
        .filter((u) => u?.label?.length > 0)
    : [];

  return (
    <PickerComponent
      fieldKey="priorities"
      optionList={urgencyOptions}
    />
  );
}
