import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import DialogTitle from 'components/dialogs/DialogTitle';
import FAIcon from 'components/ui/FAIcon';
import { useContext, useRef } from 'react';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmContext = createContext();

export default function useConfirm() {
  return useContext(ConfirmContext);
}

const defaultTitle = 'areYouSureYouWantToProceed';

function Confirm({
  isOpen,
  onConfirm,
  onDecline,
  onCancel,
  icon,
  title,
  helperText,
  confirmText,
  userConfirmText,
  setUserConfirmText,
  declineLabel = 'no',
  cancelLabel = 'cancel',
  confirmLabel = 'validate',
  children
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <DialogTitle
        title={
          <div className='flex items-center'>
            {icon && (
              <FAIcon
                collection="fas"
                icon={icon}
                size="md"
                className="mr-2"
              />
            )}
            {t(title)}
          </div>
        }
      />
      <DialogContent className="my-2">
        {children}
        {helperText && <Typography variant="body1">{t(helperText)}</Typography>}
        {confirmText && (
          <div className="flex flex-col items-start gap-4">
            <Typography variant="body1">
              {t('toConfirmMessage')} <span className="italic">{t(confirmText)}</span>
            </Typography>
            <TextField
              label={t('confirmation')}
              variant="outlined"
              fullWidth
              value={userConfirmText}
              onChange={(event) => setUserConfirmText(event.target.value)}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onCancel}
          color="secondary"
        >
          {t(cancelLabel)}
        </Button>
        {onDecline && (
          <Button
            variant="outlined"
            onClick={onDecline}
            color="primary"
          >
            {t(declineLabel)}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={onConfirm}
          color="primary"
          disabled={confirmText ? userConfirmText !== t(confirmText) : false}
        >
          {t(confirmLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(null);
  const [onCancel, setOnCancel] = useState(null);
  const [onDecline, setOnDecline] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [helperText, setHelperText] = useState();
  const [confirmText, setConfirmText] = useState();
  const [userConfirmText, setUserConfirmText] = useState('');
  const [cancelLabel, setCancelLabel] = useState();
  const [confirmLabel, setConfirmLabel] = useState();
  const [icon, setIcon] = useState();
  const [nodeChildren, setNodeChildren] = useState(null);

  const confirm = useRef(
    ({
      onConfirm,
      onCancel = () => {},
      onDecline,
      title = defaultTitle,
      icon = 'circle-check',
      helperText,
      confirmText,
      cancelLabel,
      confirmLabel,
      nodeChildren
    }) => {
      setOnCancel(() => onCancel);
      setOnDecline(() => onDecline);
      setOnConfirm(() => onConfirm);
      setTitle(title);
      setHelperText(helperText);
      setIsOpen(true);
      setConfirmText(confirmText);
      setCancelLabel(cancelLabel);
      setConfirmLabel(confirmLabel);
      setIcon(icon);
      setNodeChildren(nodeChildren);
    }
  );

  const close = useRef(() => [setIsOpen(false), setOnConfirm(null), setConfirmText(null), setUserConfirmText('')]);

  return (
    <ConfirmContext.Provider value={confirm.current}>
      <Confirm
        isOpen={isOpen}
        onDecline={onDecline ? () => [onDecline(), close.current()] : null}
        onCancel={() => [onCancel(), close.current()]}
        onConfirm={() => [onConfirm(), close.current()]}
        icon={icon}
        title={title}
        helperText={helperText}
        confirmText={confirmText}
        userConfirmText={userConfirmText}
        setUserConfirmText={setUserConfirmText}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        children={nodeChildren}
      />
      {children}
    </ConfirmContext.Provider>
  );
}
