import useKeys from '@flowsn4ke/usekeys';
import DragScrollContainer from 'components/ui/DragScrollContainer';

export default function BookmarkBar2({ bookmarks = [] }) {
  const k = useKeys();

  if (!bookmarks.length) return null;

  return (
    <DragScrollContainer
      className="relative py-1"
      sortingOrders={[]}
    >
      {bookmarks.map((bookmark, bookmarkIdx) => (
        <div key={k(bookmarkIdx)}>{bookmark}</div>
      ))}
    </DragScrollContainer>
  );
}
