import { cloneElement, useCallback, useMemo, useRef } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import { DIR_MIME_TYPE } from '../constants';
import ContextMenuItem from './ContextMenuItem';
import ContextMenuUploadItem from './ContextMenuUploadItem';
import useConfirm from 'hooks/useConfirm';
import { useAuth } from 'hooks/useAuth';
import { nanoid } from 'nanoid';
import { KeepInScreen } from 'utils/keepInScreen';

export default function ContextFileMenu({
  downloadDocuments,
  getDocuments,
  viewTrash,
  setDocumentToConsult,
  setDocumentToRename,
  setDocumentToEdit,
  setDocumentCommentsToConsult,
  restoreDocuments,
  setFolderNameDialogIsOpen,
  trashDocuments,
  deleteDocuments,
  contextMenuData,
  setContextMenuData,
  uploadFiles,
  uploadRevision,
  read_only_mode
}) {
  const auth = useAuth();
  const contextMenuRef = useRef();
  useOutsideClick(contextMenuRef, () => setContextMenuData(null));
  const closeMenu = useCallback(() => {
    setContextMenuData(null);
  }, []);

  const confirm = useConfirm();

  // one document or many documents ?
  const isManyDocuments = contextMenuData?.documents?.length > 1;

  // Vérifier si l'utilisateur a le rôle approprié ou si l'utilisateur est propriétaire du document
  const checkPermissions = (document) => {
    const user_has_owner_role = document?.owner_ids?.includes(auth?.interface?._role?._id);
    const user_has_uploaded_the_document = auth.user._id === document?.created_by?._id;
    return user_has_owner_role || user_has_uploaded_the_document;
  };

  // Si plusieurs documents sont sélectionnés, vérifier si l'utilisateur a les permissions sur tous les documents
  const can_edit_and_delete = isManyDocuments
    ? contextMenuData?.documents?.length && contextMenuData?.documents.every(checkPermissions) // Vérifier tous les documents
    : contextMenuData?.documents?.length && checkPermissions(contextMenuData?.documents[0]); // Vérifier un seul document

  // Si l'utilisateur est en mode lecture seule, vérifier les permissions
  const is_read_only = Boolean(read_only_mode || !can_edit_and_delete);

  // check if has folder in docuemnts if there are one folder, show only putInTrash and deleteForever
  const has_folder = contextMenuData?.documents?.some((doc) => doc.mimetype === DIR_MIME_TYPE);

  const menuItems = useMemo(() => {
    const fileMenuItems = [
      ...(isManyDocuments
        ? [
            ...(is_read_only
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'trash',
                      label: 'putInTrash',
                      onClick: () => trashDocuments(contextMenuData?.documents.map((doc) => doc._id))
                    }}
                  />
                ]),
            ...(has_folder || !can_edit_and_delete
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'arrow-down-to-bracket',
                      label: 'downloadFiles',
                      onClick: () =>
                        downloadDocuments({ document_ids: contextMenuData?.documents.map((doc) => doc._id) })
                    }}
                  />
                ]),
            ...(!can_edit_and_delete
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'trash-can-slash',
                      label: 'deleteForever',
                      onClick: () =>
                        confirm({
                          onConfirm: () => deleteDocuments(contextMenuData?.documents.map((doc) => doc._id)),
                          message: 'sureYouWantDeleteForeverDocument',
                          confirmText: 'permanentlyDelete'
                        })
                    }}
                  />
                ])
          ]
        : [
            <ContextMenuItem
              menu={{
                icon: 'info',
                label: 'informations',
                onClick: () => setDocumentToConsult(contextMenuData?.documents[0])
              }}
            />,
            ...(!can_edit_and_delete
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'pen-to-square',
                      label: 'edit',
                      onClick: () => setDocumentToEdit(contextMenuData?.documents[0])
                    }}
                  />,
                  <ContextMenuItem
                    menu={{
                      icon: 'i-cursor',
                      label: 'rename',
                      onClick: () => setDocumentToRename(contextMenuData?.documents[0])
                    }}
                  />
                ]),
            <ContextMenuItem
              menu={{
                icon: 'arrow-down-to-bracket',
                label: 'download',
                onClick: () => downloadDocuments({ document_ids: [contextMenuData?.documents[0]._id] })
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'message-lines',
                label: 'comments',
                onClick: () => setDocumentCommentsToConsult(contextMenuData?.documents[0])
              }}
              content_badge={
                contextMenuData?.documents?.length > 0 ? contextMenuData.documents[0]?.has_comments : false
              }
            />,
            // <ContextMenuItem
            //   menu={{ icon: "share", label: "Partager", onClick: () => { } }}
            // />,
            ...(is_read_only
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'trash',
                      label: 'putInTrash',
                      onClick: () => trashDocuments(contextMenuData?.documents.map((doc) => doc._id))
                    }}
                  />
                ]),
            ...(!can_edit_and_delete
              ? []
              : [
                  <ContextMenuUploadItem
                    label="downloadNewVersion"
                    onUpload={(event) => {
                      uploadRevision({
                        file: event.target.files[0],
                        document_id: contextMenuData?.documents[0]._id
                      });
                      closeMenu();
                    }}
                  />,
                  <ContextMenuItem
                    menu={{
                      icon: 'trash-can-slash',
                      label: 'deleteForever',
                      onClick: () =>
                        confirm({
                          onConfirm: () => deleteDocuments(contextMenuData?.documents.map((doc) => doc._id)),
                          message: 'sureYouWantDeleteForeverDocument',
                          confirmText: 'permanentlyDelete'
                        })
                    }}
                  />
                ])
          ])
    ];

    const folderMenuItems = [
      ...(isManyDocuments
        ? []
        : [
            ...(is_read_only
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'trash',
                      label: 'putInTrash',
                      onClick: () => trashDocuments([contextMenuData?.documents[0]._id])
                    }}
                  />
                ]),
            ...(!can_edit_and_delete
              ? []
              : [
                  <ContextMenuItem
                    menu={{
                      icon: 'i-cursor',
                      label: 'rename',
                      onClick: () => setDocumentToRename(contextMenuData?.documents[0])
                    }}
                  />,
                  <ContextMenuItem
                    menu={{
                      icon: 'trash-can-slash',
                      label: 'deleteForever',
                      onClick: () =>
                        confirm({
                          onConfirm: () => deleteDocuments(contextMenuData?.documents.map((doc) => doc._id)),
                          title: 'sureYouWantDeleteForeverDocumentAndContents',
                          confirmText: 'permanentlyDelete'
                        })
                    }}
                  />
                ])
          ])
    ];

    const trashedDocumentMenuItems = [
      ...(!can_edit_and_delete
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'rotate-left',
                label: 'restore',
                onClick: () => restoreDocuments(contextMenuData?.documents.map((doc) => doc._id))
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'trash-can-slash',
                label: 'deleteForever',
                onClick: () =>
                  confirm({
                    onConfirm: () => deleteDocuments(contextMenuData?.documents.map((doc) => doc._id)),
                    title: 'sureYouWantDeleteForeverDocument',
                    confirmText: 'permanentlyDelete'
                  })
              }}
            />
          ])
    ];

    const noDocumentMenuItems = [
      ...(read_only_mode
        ? [
            <ContextMenuItem
              menu={{ icon: 'rotate-right', label: 'refresh', onClick: () => getDocuments() }}
              closeMenu={closeMenu}
            />
          ]
        : [
            // <ContextMenuUploadItem label="Charger un fichier privé" onUpload={event => [uploadFiles(event.target.files), closeMenu()]} />,
            <ContextMenuUploadItem
              label="upload"
              multiple
              onUpload={(event) => [uploadFiles(event.target.files), closeMenu()]}
            />,
            <ContextMenuItem
              menu={{
                icon: 'folder-plus',
                label: 'createFolder',
                onClick: () => setFolderNameDialogIsOpen(true)
              }}
              closeMenu={closeMenu}
            />,
            <ContextMenuItem
              menu={{ icon: 'rotate-right', label: 'refresh', onClick: () => getDocuments() }}
              closeMenu={closeMenu}
            />
          ])
    ];

    const clicked_on_document_in_trash = viewTrash && contextMenuData?.documents;
    const clicked_on_folder = contextMenuData?.documents && contextMenuData?.documents[0].mimetype === DIR_MIME_TYPE;
    const clicked_on_file = Boolean(contextMenuData?.documents);
    const clicked_on_background = !viewTrash && !contextMenuData?.documents;

    return clicked_on_document_in_trash
      ? trashedDocumentMenuItems
      : clicked_on_folder
      ? folderMenuItems
      : clicked_on_file
      ? fileMenuItems
      : clicked_on_background
      ? noDocumentMenuItems
      : [];
  }, [contextMenuData]);

  const { position_top, position_left, maxHeight } = KeepInScreen(contextMenuRef, contextMenuData);

  if (!Boolean(menuItems?.length > 0)) return null;

  return (
    <div
      ref={contextMenuRef}
      style={{
        display: contextMenuData ? 'flex' : 'none',
        top: position_top || 0,
        left: position_left || 0,
        maxHeight: maxHeight || 'auto',
        overflowY: 'auto'
      }}
      className="z-[1400] min-w-[200px] isolate bg-white p-1 rounded shadow-lg w-max h-auto fixed flex flex-col"
    >
      {menuItems.map((menu) => cloneElement(menu, { key: nanoid(), closeMenu }))}
    </div>
  );
}
