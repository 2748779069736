import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import Ticket from 'entities/Ticket/Ticket';
import { useMemo } from 'react';
import { useAuth } from 'hooks/useAuth';

export const TicketView = ({ isTrash, ticketId, setActiveTicket, page, defaultFilters }) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const isSmallScreen = useMemo(() => window.innerWidth < 1024, []);
  const isDialog = isSmallScreen && !page;

  if (page && !ticketId) {
    return (
      <>
        <div className="text-gray-400 w-full h-full flex flex-col gap-3 items-center justify-center">
          <FAIcon
            noIconClass
            collection="fal"
            icon="files"
            size="2xl"
          />
          <span className="text-gray-400 text-sm">{t('entityTicketPick')}</span>
        </div>
        <Ticket
          showList={false}
          page={page}
        />
      </>
    );
  } else if (!page && !ticketId)
    return (
      <Ticket
        showList={false}
        page={page}
        trash={isTrash}
        defaultForm={{
          ...(defaultFilters?.locations?.length ? { _locations: [defaultFilters?.locations?.[0]] } : {}),
          ...(defaultFilters?.equipments?.length ? { _equipments: [defaultFilters?.equipments?.[0]] } : {}),
          ...(defaultFilters?.managers?.length ? { _managers: [defaultFilters?.managers?.[0]] } : {}),
          ...(auth?.interface?.isContractor ? { _client: defaultFilters?.clients?.[0] } : {})
        }}
      />
    );
  else
    return (
      <Ticket
        isDialog={isDialog}
        noFetch
        page={page}
        showList={false}
        defaultOpenView
        childrenId={ticketId}
        childrenAuto
        afterDialogClose={() => setActiveTicket(null)}
        trash={isTrash}
      />
    );
};

export default TicketView;
