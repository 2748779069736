import { Box, Tooltip, makeStyles, IconButton } from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { omit } from 'lodash-es';
import useStyles from 'layouts/entities/Preview.styles';
import { contractStates } from 'lists/contractStates';

import moment from 'moment';
import FAIcon from 'components/ui/FAIcon';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { computeState } from 'store/ticketsSlice';
import classNames from 'utils/classNames';
import { differenceInDays } from 'date-fns';

const useStyles2 = makeStyles((theme) => ({
  dashed: ({ isLate, isAlert, selected }) => ({
    width: 1,
    top: 0,
    height: '100%',
    display: selected ? 'none' : 'block',
    zIndex: 5,
    border: `1px dashed ${
      selected ? 'white' : isLate ? '#c0392b' : isAlert ? '#d35400' : theme.palette.secondary.main
    }`,
    position: 'absolute'
  }),
  solid: ({ isLate, isAlert, selected }) => ({
    width: 1,
    top: 0,
    height: '100%',
    display: selected ? 'none' : 'block',
    zIndex: 5,
    border: `1px solid ${selected ? 'white' : isLate ? '#c0392b' : isAlert ? '#d35400' : theme.palette.secondary.main}`,
    position: 'absolute'
  }),
  icon: ({ isLate, isAlert, selected }) => ({
    display: selected && 'none',
    color: `${selected ? 'white' : isLate ? '#c0392b' : isAlert ? '#d35400' : theme.palette.secondary.main}`,
    transform: 'translateX(-13px) translateY(9px)',
    background: selected ? 'transparent' : 'white',
    position: 'relative',
    borderRadius: 8
  }),
  badge: {
    position: 'relative',
    right: 4,
    marginRight: 2
  },
  ecart: {
    marginLeft: 8,
    fontSize: 12
  },
  red: {
    color: theme.palette.error.light
  },
  green: {
    color: theme.palette.success.light
  },
  checked: {
    color: theme.palette.success.light,
    marginLeft: 6
  }
}));

export default function PeriodPreview({ element: { item, itemContext } }) {
  const dbTicket = useSelector((state) => state['tickets']).db;

  const { t } = useTranslation();

  const c = useStyles();

  const ticket = dbTicket[item.period._ticket._id] || {
    ...item.period._ticket,
    period: {
      ...omit(item.period, ['_ticket']),
      checked: item.period._ticket.preventif_checked,
      hasIR: item.period._ticket.hasIR,
      hasReserve: item.period._ticket.hasReserveUrgency
    },
    contract: {
      state: computeState(item.period._ticket, item.period._ticket),
      visit_date: item.period._ticket.visit_date || item.period._ticket?._parent?.intervention_date,
      intervention_date: item.period._ticket.intervention_date
    }
  };

  const selected = ticket.period.hasReserve;

  const offSet = 2;

  const { date, isLate, isAlert, alertPosition, planningPosition, datePosition } = useMemo(() => {
    const isAlert =
      ticket.period.current &&
      true &&
      moment().isSameOrAfter(ticket.period.alert_date) &&
      moment().isSameOrBefore(ticket.period.end_time);
    const isLate = ticket.period.current && moment().isAfter(ticket.period.end_time);

    const start_time = moment(ticket.period.start_time);

    const alertPosition =
      !isLate &&
      ticket.period.current &&
      ticket.period.alert_date &&
      ticket.state !== 'finished' &&
      (itemContext.width * start_time.diff(ticket.period.alert_date, 'days')) / start_time.diff(item.end_time, 'days') -
        offSet;

    const planningPosition =
      !isLate &&
      ticket.period.current &&
      ticket.period.planning_date &&
      (itemContext.width * start_time.diff(ticket.period.planning_date, 'days')) /
        start_time.diff(item.end_time, 'days') -
        offSet;

    const date = ticket.contract.intervention_date || ticket.contract.visit_date;
    const datePosition =
      ticket.period.current &&
      date &&
      (itemContext.width * start_time.diff(date, 'days')) / start_time.diff(item.end_time, 'days') - offSet;

    return {
      date,
      isAlert,
      isLate,
      start_time,
      alertPosition,
      planningPosition,
      datePosition
    };
  }, [
    ticket.contract.visit_date,
    ticket.contract.intervention_date,
    ticket.period.current,
    itemContext.width,
    ticket.period.start_time,
    ticket.period.end_time
  ]);

  const state = contractStates[ticket.contract.state];

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const c2 = useStyles2({ isLate, isAlert, selected: selected });

  return (
    <Box style={{ width: '100%' }}>
      <Tooltip
        placement={'right'}
        title={'Début de periode le ' + moment(item.period.start_time).format('LLL')}
      >
        <Box
          position={'absolute'}
          top={'0px'}
          left={'0px'}
          height={'100%'}
          width={'20px'}
        ></Box>
      </Tooltip>
      <Tooltip
        placement={'left'}
        title={'Fin de periode le ' + moment(item.period.end_time).format('LLL')}
      >
        <Box
          position={'absolute'}
          top={'0px'}
          right={'0px'}
          height={'100%'}
          width={'20px'}
        ></Box>
      </Tooltip>
      <Box className={c.l}>
        {/* {selected && <DragIndicator style={{ color: 'white', position: 'relative', right: 10 }} />} */}

        <span
          className={c.title}
          style={{
            color: !ticket.period.current || selected ? 'white' : state.color,
            fontSize: 12,
            fontWeight: 500
          }}
        >
          {!!isAlert && (
            <Tooltip
              placement={'left'}
              title={'À surveiller'}
            >
              <Box
                style={{
                  marginLeft: 6,
                  color: selected ? 'white' : '#d35400',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FAIcon
                  className={c2.badge}
                  style={{ fontSize: 14 }}
                  collection={'fas'}
                  icon={'exclamation-triangle'}
                />
              </Box>
            </Tooltip>
          )}

          {!!isLate && (
            <Tooltip
              placement={'left'}
              title={'En retard'}
            >
              <Box
                style={{
                  marginLeft: 6,
                  color: selected ? 'white' : '#c0392b',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FAIcon
                  className={c2.badge}
                  collection={'fas'}
                  icon={'siren-on'}
                />
              </Box>
            </Tooltip>
          )}

          <Box
            display={'flex'}
            flexDirection={'column'}
          >
            <Box>
              #{ticket.period.index} {t(state.label)}
              {(!ticket.period.current || isLate) && <Ecart period={item.period} />}
            </Box>

            {!ticket.period.current && (
              <span style={{ fontSize: 11, display: 'flex', alignItems: 'center' }}>
                <FAIcon
                  collection={'fas'}
                  icon={'calendar'}
                  style={{ fontSize: 10, marginRight: 6 }}
                />
                {/* //@TRANSLATE */}
                {`Réalisée le ${moment(date).format('DD/MM/YYYY')}`}
              </span>
            )}
          </Box>

          {ticket.period.hasIR && ticket.period.current && (
            <Tooltip
              placement={'right'}
              title={'À un rapport'}
            >
              <IconButton size={'small'}>
                <FAIcon
                  style={{ color: selected ? 'white' : 'inherit' }}
                  collection={'fas'}
                  icon={'file-chart-pie'}
                />
              </IconButton>
            </Tooltip>
          )}
        </span>
      </Box>

      {!!planningPosition && (
        <Tooltip
          placement="left"
          title={'Maintenance à planifier a partir du ' + moment(ticket.period.planning_date).format('LL')}
        >
          <span
            style={{ left: planningPosition }}
            className={c2.dashed}
          ></span>
        </Tooltip>
      )}

      {!!alertPosition && (
        <Tooltip
          placement="left"
          title={'Rappel le ' + moment(ticket.period.alert_date).format('LL')}
        >
          <span
            style={{ left: alertPosition }}
            className={c2.solid}
          >
            <FAIcon
              className={c2.icon}
              collection={'fal'}
              icon={'bell-on'}
            />
          </span>
        </Tooltip>
      )}

      {!!datePosition && (
        <Tooltip
          placement="left"
          title={
            (ticket.contract.intervention_date ? 'Date de réalisation' : 'Date de passage programmé') +
            ' ' +
            moment(date).format('LL')
          }
        >
          <span
            style={{ left: datePosition }}
            className={c2.solid}
          >
            <FAIcon
              className={c2.icon}
              collection={'fal'}
              icon={'calendar-day'}
            />
          </span>
        </Tooltip>
      )}

      {/* TODO: Should be in Timeline */}
      <ConfirmDialog
        text={<span>{t('doYouWantDeleteThePeriod')}</span>}
        open={confirmDeleteOpen}
        onClose={() => [setConfirmDeleteOpen(false)]}
        onConfirm={() => alert()}
      />
    </Box>
  );
}

export function Ecart({ period }) {
  const { t } = useTranslation();
  const ecart =
    period.ecart || period.days_diff || differenceInDays(new Date(period.end_time || period.end), new Date());

  return (
    <Box
      component="span"
      display="inline-flex"
      alignItems="center"
      className="font-bold ml-2 flex gap-0.5"
    >
      {ecart >= 0 ? (
        <Tooltip
          placement="right"
          title={Math.abs(ecart) + ` ${t('daysInAdvance')}`}
        >
          <Box className="text-green-500">+{Math.abs(ecart)}j</Box>
        </Tooltip>
      ) : ecart < 0 ? (
        <Tooltip
          placement="right"
          title={Math.abs(ecart) + ` ${t('daysLate')}`}
        >
          <Box
            className="text-red-500"
            component="span"
          >
            -{Math.abs(ecart)}j
          </Box>
        </Tooltip>
      ) : (
        <></>
      )}

      {period.checked && (
        <Tooltip
          placement="right"
          title={t('validatedPeriod')}
        >
          <CheckRounded className='text-green-500'/>
        </Tooltip>
      )}
    </Box>
  );
}
