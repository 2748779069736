import { IconButton, InputBase, Tooltip } from '@material-ui/core';
import { LayersClear } from '@material-ui/icons';
import FilterSetsManager from 'components/filters/FilterSetsManager';
import Menu from 'components/menu/Menu';
import FAIcon from 'components/ui/FAIcon';
import { useForm, useResetListener } from 'frmx';
import useDebouncedState from 'hooks/useDebouncedState';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function SearchBar({
  totalResults,
  filterSetsManager,
  setFilterSetsManager,
  path,
  menuItems,
  downloadLoading,
  resizeable,
  setResize,
  setInitialValues,
  appliedFilters,
  hiddenFilters,
  dataFilters,
  onSubmit,
  listAction,
  entity,
  limit
}) {
  const { t } = useTranslation();
  const { resetForm, getOneField, setOneField, handleSubmit } = useForm();

  const [search, setSearch, debouncedSearch] = useDebouncedState(getOneField(path));
  const prevSearchRef = useRef(search);
  
  useEffect(() => {
    const prevSearch = prevSearchRef.current;
  
    // si la valeur de `search` a changé
    if (prevSearch !== debouncedSearch) {
      getOneField(path) !== debouncedSearch && setOneField(path, debouncedSearch);
      if (onSubmit) handleSubmit();
    }
  
    // Mettre à jour la référence avec la valeur actuelle de `debouncedSearch`
    prevSearchRef.current = debouncedSearch;
  }, [debouncedSearch]);
  useResetListener(() => setSearch(''));

  return (
    <div className="w-full flex gap-1 items-center justify-around h-12 px-2 pt-1">
      <Menu
        button={() => (
          <IconButton size="small">
            <FAIcon
              collection="fas"
              icon={downloadLoading ? 'spinner-third' : 'ellipsis-vertical'}
              size="medium"
              className={`${downloadLoading && 'animate-spin'}`}
            />
          </IconButton>
        )}
        items={menuItems}
      />
      <InputBase
        className="block font-normal w-full sm:text-sm md:text-md p-1 outline-none"
        placeholder={
          totalResults > 0  ? t('searchAmongNResults', { count: totalResults }) : t('searchEtc')
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        autoFocus
      />
      <div className="flex items-center justify-center gap-2 mr-2">
        {listAction && listAction}

        <Tooltip
          title={t('resetFilters')}
          placement="left"
        >
          <IconButton
            button
            onClick={() => {
              resetForm();
              handleSubmit();
            }}
            className="!p-1.5"
          >
            <LayersClear />
          </IconButton>
        </Tooltip>
        {resizeable && (
          <IconButton
            button
            onClick={() => setResize((prev) => !prev)}
          >
            <FAIcon
              collection="far"
              icon="chevron-left"
              size="sm"
            />
          </IconButton>
        )}
        {filterSetsManager && (
          <FilterSetsManager
            dataFilters={dataFilters}
            appliedFilters={appliedFilters}
            setInitialValues={setInitialValues}
            filterSetsManager={filterSetsManager}
            setFilterSetsManager={setFilterSetsManager}
            entity={entity}
            limit={limit}
            hiddenFilters={hiddenFilters}
          />
        )}
      </div>
    </div>
  );
}
