const EquipmentFormValues = (configuration, { sublistParent }) => ({
  isContract: false,
  _jobs: [],
  name: '',
  serial: '',
  parentEquipment: sublistParent?._id,
  _location: sublistParent ? sublistParent._location : null,
  model: '',
  buy_date: '',
  brand: '',
  year: '',
  state: '',
  operational_state: 'FUNCTIONAL',
  status: 'ACTIVE',
  _client: sublistParent && configuration.isContractor ? sublistParent?._client._id : null,
  _category: null,
  _subcategory: null,
  revision_date: '',
  replacement_date: '',
  _equipment: null,
  description: '',
  criticity: 'NOT_SPECIFIED',
  _cover: null
});
export default EquipmentFormValues;

export const EquipmentValidation = (configuration) => ({});

export const formatForm = (dataFromForm, isCreate, attachments, _interface) => {
  dataFromForm._cover = attachments[0]?.url;

  if (_interface.isClient) {
    dataFromForm._client = _interface._company._id;
  } else if (dataFromForm._location && dataFromForm._location._client) {
    dataFromForm._client = dataFromForm._location._client;
  }

  return dataFromForm;
};
