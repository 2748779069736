import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect, useRef, useState } from 'react';
import { getElements as getInterveners } from 'store/intervenersSlice';
import { exportTickets, getTicketBoomarks, newTicketList, syncTicketList } from 'store/ticketsSlice.js';
import { formatFilter, LIMIT } from './baseFilters.js';
import { normalizeFilters, prepareFiltersForApi } from './utils.js';
import MenuItem from 'components/menu/MenuItem.js';
import Menu from 'components/menu/Menu.js';
import { Button, Checkbox, InputBase } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon.js';
import { useConfiguration } from 'hooks/useConfiguration.js';
import { useTranslation } from 'react-i18next';
import { CloudDownload } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import useNotifications from 'hooks/useNotifications.js';
import TicketExportsNew from 'entities/Ticket/TicketExportsNew.js';
import { useAuth } from 'hooks/useAuth.js';
import { getAssociatedFiltersFromBookmarks } from './Filters/transformFilters.js';
import { useSelector } from 'react-redux';

export const syncTickets = ({ dispatch }) => {
  dispatch(syncTicketList);
};

export const fetchTickets = ({ auth, setData, dispatch, setSkip, skip, limit, setHasMore }) => {
  return async (filters, isSkipped = false, forceLoading = true) => {
    if (!isSkipped && forceLoading) {
      setData({ tickets: null, count: null });
    }

    const nextSkip = isSkipped ? skip + limit : 0;
    setSkip(nextSkip); // State update outside async flow (to avoid infinite loop)

    const formattedFilters = formatFilter(filters, auth, nextSkip, limit);

    try {
      dispatch(
        newTicketList,
        { filters: formattedFilters },
        {
          onSuccess: (data) => {
            const newTickets = data?.tickets || [];
            const allTicketsLoaded = newTickets.length < limit;

            setHasMore(!allTicketsLoaded); // Indique si d'autres tickets sont disponibles

            setData((prevData) => ({
              tickets: isSkipped ? [...prevData.tickets, ...data?.tickets] : data?.tickets,
              count: data.count
            }));
          },
          onError: () => {
            setData({ tickets: [], count: 0 });
            setHasMore(false);
          }
        }
      );
    } catch {
      setData({ tickets: [], count: 0 });
      setHasMore(false);
    }
  };
};

export const useFilteredBookmarks = (calendar, appliedFilters, setBookmarkState, initialBookmarks) => {
  const { dispatch } = useAsyncDispatch();
  const auth = useAuth();
  const previousFiltersRef = useRef(null); // Garde la trace des filtres précédents
  const isFirstRun = useRef(true); // Indique si c'est la première exécution

  useEffect(() => {
    const hasFiltersChanged = () => {
      const { bookmarks, ...currentFilters } = appliedFilters;
      const previousFilters = previousFiltersRef.current;

      // Si c'est la première exécution, ou si les filtres ont changé (hors `bookmarks`)
      return (
        (isFirstRun.current || JSON.stringify(currentFilters) !== JSON.stringify(previousFilters)) && !bookmarks?.length
      );
    };

    if (hasFiltersChanged()) {
      // Met à jour la référence des filtres et désactive le flag de première exécution
      previousFiltersRef.current = { ...appliedFilters, bookmarks: undefined };
      isFirstRun.current = false;

      const fetchBookmarks = async () => {
        setBookmarkState({ bookmarks: initialBookmarks, isLoading: true });
        const requests = getAssociatedFiltersFromBookmarks(
          initialBookmarks.map((bookmark) => bookmark.key),
          appliedFilters,
          auth
        );

        if (!calendar) {
          dispatch(
            getTicketBoomarks,
            { requests },
            {
              onSuccess: (data) => {
                const bookmarks = data
                  .filter((bookmark) => bookmark.val)
                  .map((bookmark) => {
                    const initialBookmark = initialBookmarks.find((bm) => bm.key === bookmark.key);
                    return {
                      ...bookmark,
                      color: initialBookmark?.color,
                      label: initialBookmark?.label
                    };
                  });

                setBookmarkState({
                  bookmarks,
                  isLoading: false
                });
              },
              onError: () => {
                setBookmarkState({
                  bookmarks: [],
                  isLoading: false
                });
              }
            }
          );
        } else {
          dispatch(getInterveners, {
            limit: { startIndex: 0, stopIndex: LIMIT },
            filters: {
              tab: { collaborator: true, mine: false, public: false },
              deleted: false,
              sort: { sort: 1, value: 'companyName' }
            }
          }).then(({ data }) => {
            setBookmarkState({
              bookmarks: [
                initialBookmarks[0],
                ...data.elements?.slice(0, 9).map(({ _id, color, firstName, lastName }) => ({
                  label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
                  key: _id,
                  color
                }))
              ],
              isLoading: false
            });
          });
        }
      };

      fetchBookmarks();
    }
  }, [appliedFilters, calendar, auth, setBookmarkState, initialBookmarks, dispatch]);
};

const getDefaultSet = (appliedFilters) => ({
  title: 'defaultFilterSet',
  filters: normalizeFilters({ filters: appliedFilters }),
  _id: '123'
});

export const createFilterSetsManager = (appliedFilters, showCustomFilters, entity) => {
  const last_used_filter_set = localStorage.getItem(`last_used_filter_set_${entity}`);
  if (last_used_filter_set && !appliedFilters.deleted && showCustomFilters) return JSON.parse(last_used_filter_set);
  else
    return {
      filterSets: [getDefaultSet(appliedFilters)],
      selectedSet: getDefaultSet(appliedFilters),
      orderFilters: Object.keys(appliedFilters)
    };
};

export const generateFilters = (orderFilters, showableFilters) =>
  orderFilters
    .map((path) => showableFilters.find((f) => f.props.path === path))
    .filter(Boolean)
    .map(({ Component, props }) => (
      <Component
        key={props.label}
        {...props}
      />
    ));

export const ticketMenuItems = ({ t, fetchTickets, appliedFilters, setIsTrashOpen, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="right-start"
    />,
    <MenuItem
      icon="trash"
      label="trash"
      onClick={() => setIsTrashOpen(true)}
    />
  ];
};

const PopoverExportTickets = ({ appliedFilters }) => {
  const config = useConfiguration();
  const customFields = useSelector((store) => store.fieldSections).ticket;
  const ticketExport = TicketExportsNew(config, customFields?.[0]?.fields);
  const [selectedFields, setSelectedFields] = useState(ticketExport.map(({ key }) => key));
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();

  // const toggleSelectAll = () => {
  //   setSelectedFields(selectedFields.length === ticketExport.length ? [] : ticketExport.map(({ key }) => key));
  // };

  const handleSelectField = ({ key, checked }) => {
    setSelectedFields((prev) => (checked ? [...prev, key] : prev.filter((field) => field !== key)));
  };

  const handleExport = () => {
    setIsLoading(true);

    let filters = prepareFiltersForApi({ filters: appliedFilters });
    filters = filters.filter((f) => f.key !== 'limit' && f.key !== 'skip');

    try {
      dispatch(exportTickets, { filters, fields: selectedFields }).then((res) => {
        const filename = 'tickets.csv';

        const csvExport = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64;charset=UTF-8'
        });
        saveAs(csvExport, filename);
        notify.success(t('exportSuccessMessage'));
        setIsLoading(false);
      });
    } catch {
      notify.error();
      setIsLoading(false);
    }
  };

  const filteredTickets = ticketExport.filter(({ label }) => t(label).toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="flex flex-col gap-1 p-1">
      {/* <div className="flex gap-1 items-center"> */}
      <InputBase
        className="px-4 py-2"
        placeholder={t('findValue')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {/* <Button
          className="!text-xs"
          onClick={toggleSelectAll}
          disabled={search.length}
          >
          {selectedFields.length === ticketExport.length ? t('unselectAll') : t('selectAll')}
          </Button>
      </div> */}

      <div className="max-h-[50vh] w-80 overflow-auto">
        {filteredTickets.map(({ label, key }) => {
          const isChecked = selectedFields.includes(key);
          return (
            <div
              key={key}
              className="flex items-center gap-1 px-1 hover:bg-gray-100 rounded-lg cursor-pointer"
              onClick={() => handleSelectField({ key, checked: !isChecked })}
            >
              <Checkbox
                color={'primary'}
                checked={isChecked}
              />
              <span className="text-sm py-1">{t(label)}</span>
            </div>
          );
        })}
      </div>

      <Button
        variant="outlined"
        onClick={handleExport}
        endIcon={
          isLoading ? (
            <FAIcon
              icon="spinner"
              className="fa-spin"
              collection="fas"
              size="medium"
            />
          ) : (
            <CloudDownload />
          )
        }
        disabled={isLoading || !selectedFields.length}
        className="!rounded-lg"
      >
        {t('export')}
      </Button>
    </div>
  );
};

export const trashMenuItems = ({ t, fetchTickets, appliedFilters, count }) => {
  return [
    <MenuItem
      icon="rotate-right"
      label="refresh"
      onClick={() => fetchTickets(appliedFilters)}
    />,
    <Menu
      button={() => (
        <MenuItem
          icon="download"
          label={t('ExportXEls', { count })}
          hasSubMenu
        />
      )}
      items={[<PopoverExportTickets appliedFilters={appliedFilters} />]}
      placement="left-start"
    />
  ];
};
