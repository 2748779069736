import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import Menu from 'components/menu/Menu';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useAuth } from 'hooks/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transformNewSystemToOld, normalizeFilters } from 'router/tickets/utils';
import { deleteUserFilterSets, getUserFilterSets, updateUserFilterSets } from 'store/usersSlice';
import { ActionCustomFilterSet } from './ActionCustomFilterSet';
import FilterDialog from './FilterDialog';
import useNotifications from 'hooks/useNotifications';
import { Save, Tune } from '@material-ui/icons';
import { truncateText } from 'utils/uiUtils';

export default function FilterSetsManager({
  filterSetsManager,
  setFilterSetsManager,
  setInitialValues,
  appliedFilters,
  hiddenFilters,
  dataFilters,
  entity,
  limit
}) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, filterSet: null });
  const [isCreate, setIsCreate] = useState(false);
  const [selectedFilterSet, setSelectedFilterSet] = useState(filterSetsManager.filterSets[0]);
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const auth = useAuth();
  const entityFilters = entity + 'Filters';

  const fetchFilterSetManagers = useCallback(() => {
    try {
      dispatch(
        getUserFilterSets,
        { type: entityFilters },
        {
          onSuccess: (data) => {
            setFilterSetsManager((prev) => ({
              ...prev,
              filterSets: [
                ...prev.filterSets,
                ...data.elements.filter(
                  (newElement) => !prev.filterSets.some((existingElement) => existingElement._id === newElement._id)
                )
              ]
            }));
          }
        },
        { id: auth?.user._id }
      );
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, []);

  const handleDialogToggle = () => setIsDialogOpen((prev) => !prev);

  const handleSaveFilterSet = (filters) => {
    let { filters: filterList, _id, title } = filters[entityFilters];

    // delete doublons
    filterList = filterList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.key === value.key && JSON.stringify(t.val) === JSON.stringify(value.val) && t.op === value.op
        )
    );

    dispatch(
      updateUserFilterSets,
      {
        filter_id: _id,
        filters: filterList,
        title: title,
        type: entityFilters
      },
      {
        onSuccess: () => {
          const isNewSet = !filterSetsManager.filterSets.some((set) => set._id === _id);
          const updatedFilterSets = isNewSet
            ? [...filterSetsManager.filterSets, filters[entityFilters]]
            : filterSetsManager.filterSets.map((set) => (set._id === _id ? filters[entityFilters] : set));

          const filterSet = {
            selectedSet: filters[entityFilters],
            orderFilters: filterList.map((filter) => filter.key),
            filterSets: updatedFilterSets
          };

          setFilterSetsManager(filterSet);

          localStorage.setItem(`last_used_filter_set_${entity}`, JSON.stringify(filterSet));

          handleDialogToggle();
          // timeout pour setInitialValues pour que l'animation de la modale se fasse bien
          setTimeout(() => {
            setInitialValues(transformNewSystemToOld({ filters: filterList }));
          }, 200);
        }
      },
      { id: auth?.user._id }
    );
  };

  const handleDeleteFilterSet = () => {
    const { filterSet } = deleteDialog;

    dispatch(
      deleteUserFilterSets,
      { type: entityFilters },
      {
        onSuccess: () => {
          const updatedFilterSets = filterSetsManager.filterSets.filter((set) => set._id !== filterSet._id);

          const newSelectedSet =
            filterSetsManager.selectedSet._id === filterSet._id ? updatedFilterSets[0] : filterSetsManager.selectedSet;

          const newFilterSet = {
            selectedSet: newSelectedSet,
            orderFilters: newSelectedSet.filters.map((filter) => filter.key),
            filterSets: updatedFilterSets
          };

          setFilterSetsManager(newFilterSet);

          setDeleteDialog({ open: false, filterSet: null });

          // if I select default filter set i want to reset the localStorage
          if (filterSetsManager.selectedSet._id === filterSet._id)
            localStorage.removeItem(`last_used_filter_set_${entity}`);
          else localStorage.setItem(`last_used_filter_set_${entity}`, JSON.stringify(newFilterSet));

          // timeout pour setInitialValues pour que l'animation de la modale se fasse bien
          setTimeout(() => {
            setInitialValues(transformNewSystemToOld({ filters: newSelectedSet.filters }));
          }, 200);
        }
      },
      {
        id: auth?.user._id,
        filterId: filterSet._id
      }
    );
  };

  const selectionFilterSet = (filterSet) => {
    const newFilterSet = {
      ...filterSetsManager,
      selectedSet: filterSet,
      orderFilters: filterSet.filters.map((filter) => filter.key)
    };

    setFilterSetsManager(newFilterSet);
    
    // if I select default filter set i want to reset the localStorage
    if (filterSet._id === '123') localStorage.removeItem(`last_used_filter_set_${entity}`);
    else localStorage.setItem(`last_used_filter_set_${entity}`, JSON.stringify(newFilterSet));
    
    setInitialValues(transformNewSystemToOld({ filters: filterSet.filters }));
  };

  const renderDeleteDialog = () => (
    <Dialog
      open={deleteDialog.open}
      onClose={() => setDeleteDialog({ open: false, filterSet: null })}
      maxWidth="md"
    >
      <DialogTitle>
        {t('deleteFilterTitle')} <span className="text-red-500">{truncateText(deleteDialog?.filterSet?.title)}</span>
      </DialogTitle>
      <DialogContent className="text-sm">{t('areYouSureYouWantToDeleteFilter')}</DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialog({ open: false, filterSet: null })}>{t('cancel')}</Button>
        <Button
          onClick={handleDeleteFilterSet}
          color="primary"
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const items = [
    ...filterSetsManager.filterSets.map((filterSet) => (
      <ActionCustomFilterSet
        key={filterSet.title}
        label={filterSet.title}
        isChecked={filterSet._id === filterSetsManager.selectedSet._id}
        selectionFilter={() => selectionFilterSet(filterSet)}
        onEdit={() => {
          setSelectedFilterSet(filterSet);
          setIsCreate(false);
          handleDialogToggle();
        }}
        onDelete={() => setDeleteDialog({ open: true, filterSet })}
      />
    )),
    <button
      key="customize-filter"
      onClick={() => {
        setIsCreate(true);
        handleDialogToggle();
      }}
      className="h-12 rounded-lg mt-1 text-sm text-gray-800 shadow hover:bg-gray-100"
    >
      {t('createSetFilter')}
    </button>
  ];

  useEffect(() => {
    fetchFilterSetManagers();
  }, []);

  const saveChanges = () => {
    dispatch(
      updateUserFilterSets, // Action pour la mise à jour des filtres côté backend
      {
        filter_id: filterSetsManager.selectedSet._id,
        filters: normalizeFilters({ filters: appliedFilters }), // Transformation avant envoi,
        title: filterSetsManager.selectedSet.title,
        type: entityFilters
      },
      {
        onSuccess: () => {
          // Mise à jour des sets de filtres avec les valeurs des `appliedFilters`
          const updatedFilterSets = filterSetsManager.filterSets.map((filterSet) => {
            if (filterSet._id === filterSetsManager.selectedSet._id) {
              return {
                ...filterSet, // Assure que les autres propriétés sont préservées
                filters: normalizeFilters({ filters: appliedFilters })
              };
            }
            return filterSet;
          });

          const updatedSelectedSet = {
            ...filterSetsManager.selectedSet, // Assure que les autres propriétés sont préservées
            filters: normalizeFilters({ filters: appliedFilters })
          };

          const newFilterSet = {
            ...filterSetsManager,
            filterSets: updatedFilterSets,
            selectedSet: updatedSelectedSet
          };

          setFilterSetsManager(newFilterSet);

          localStorage.setItem(`last_used_filter_set_${entity}`, JSON.stringify(newFilterSet));

          setInitialValues(transformNewSystemToOld({ filters: updatedSelectedSet.filters }));
          notify.success();
        },
        onError: () => {
          notify.error();
        }
      },
      { id: auth?.user._id }
    );
  };

  return (
    <>
      <Menu
        button={() => (
          <Tooltip
            title={t('your-filters')}
            placement="left"
          >
            <IconButton className="!p-1.5 relative">
              {filterSetsManager.selectedSet.title !== 'defaultFilterSet' && (
                <svg
                  className="absolute -top-0.5 -right-0.5 w-3 h-3"
                  viewBox="0 0 10 10"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    className="fill-green-500"
                  />
                </svg>
              )}
              {/* <FAIcon
              collection="far"
              icon="sliders-up"
              size="small"
            /> */}
              <Tune />
            </IconButton>
          </Tooltip>
        )}
        className="!p-1.5"
        items={items}
      />
      {filterSetsManager.selectedSet.title !== 'defaultFilterSet' && (
        <Tooltip
          title={t('saveChanges')}
          placement="left"
        >
          <IconButton
            className="!p-1.5"
            onClick={saveChanges}
          >
            {/* <FAIcon
              collection="far"
              icon="floppy-disk"
              size="small"
            /> */}
            <Save />
          </IconButton>
        </Tooltip>
      )}

      <FilterDialog
        open={isDialogOpen}
        onClose={handleDialogToggle}
        onSave={handleSaveFilterSet}
        defaultFilterSet={filterSetsManager.filterSets[0]}
        selectedFilterSet={selectedFilterSet}
        dataFilters={dataFilters}
        isCreate={isCreate}
        entity={entity}
        limit={limit}
        hiddenFilters={hiddenFilters}
      />
      {renderDeleteDialog()}
    </>
  );
}
