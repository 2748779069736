import Ticket from 'entities/Ticket/Ticket';
import TicketFile from 'router/tickets/TicketFile';

export const TestTicketList = () => {
  return (
    <div className="w-full h-[85vh] m-2 flex flex-wrap gap-6 justify-center align-center overflow-auto">
      <PreviousTicketList />
      <NewTicketList />
    </div>
  );
};

const PreviousTicketList = () => {
  return (
    <div className="h-full w-[600px] bg-red-200 flex flex-col">
      <Ticket />
    </div>
  );
};

const NewTicketList = () => {
  return (
    <div className="h-full w-[600px] bg-green-200 flex flex-col">
      <TicketFile showCustomFilters showFilters />
    </div>
  );
};
