import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core';

import { Cancel, Star, MoreVertOutlined, Notifications } from '@material-ui/icons';

import Contract from './Contract';
import { ContractorItem } from 'components/views/ViewItems';
import { useAuth } from 'hooks/useAuth';
import { useContract } from 'contexts/entities/contractContext';
import { useState } from 'react';
import UserChip from 'components/Interface/UserChip';

import useNotifications from 'hooks/useNotifications';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';

import useStylesTicket from '../TicketView.styles';
import FAIcon from 'components/ui/FAIcon';
import useKeys from '@flowsn4ke/usekeys';
import RateDialog from 'components/dialogs/RateDialog';
import { useRole } from 'hooks/useRole';
import { relanceIntervener, cancelIntervener } from 'store/ticketsSlice';
import { useTranslation } from 'react-i18next';

export default function ContractContractor({ ticket }) {
  const auth = useAuth();
  const role = useRole();
  const { contract, contractContractor, order, dispatch } = useContract();
  const { t } = useTranslation();

  const invoice_is_being_processed = Boolean(
    contract.factures?.length &&
      contract.facture_state &&
      (contract.facture_state.lock ||
        [contract.facture_state.check_1, contract.facture_state.check_2].some(
          (v) => v !== 2 && v > -1
        ))
  );

  const k = useKeys();

  const contractor = contract._summons && contract._summons[0];

  const rating = (contractor?.partner?.ratings || []).find((r) => r._ticket === order._id);
  const canRate = contractor?.partner && !rating;

  const canUpdate = role.permission('tickets', 'assign_ticket');

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isRatingOpen, setIsRatingOpen] = useState(false);

  const [relanceMenuOpen, setRelanceMenuOpen] = useState(null);
  const [contractContractorMenuOpen, setContractContractorMenuOpen] = useState(null);
  const contractContractorActions = contractContractor && (
    <Box
      display="flex"
      alignItems="center"
    >
      {contractContractor._managers?.map((manager, i) => (
        <UserChip
          key={k(i)}
          user={manager}
          type={auth.interface.type}
          style={{ marginLeft: 6 }}
        />
      ))}
    </Box>
  );
  const contractContractorActionsVisible = contractContractor && (
    <Box
      display="flex"
      alignItems="center"
    >
      <IconButton
        size="small"
        onClick={(e) => [
          e.preventDefault(),
          e.stopPropagation(),
          setContractContractorMenuOpen(e.currentTarget)
        ]}
        style={{ marginLeft: 6 }}
      >
        <MoreVertOutlined />
      </IconButton>
    </Box>
  );
  const contractContractorMenu = contractContractor && (
    <Menu
      anchorEl={contractContractorMenuOpen}
      transitionDuration={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={!!contractContractorMenuOpen}
      onClose={() => setContractContractorMenuOpen(null)}
    >
      {role.permission('tickets', 'relance') && (
        <MenuItem
          dense
          onClick={(e) => [setRelanceMenuOpen(e.currentTarget)]}
        >
          <ListItemIcon>
            <Notifications />
          </ListItemIcon>
          <ListItemText primary={t('remindIntervener')} />
        </MenuItem>
      )}
      {canRate && (
        <MenuItem
          dense
          onClick={(e) => [setIsRatingOpen(true), setContractContractorMenuOpen(null)]}
        >
          <ListItemIcon>
            <Star style={{ color: '#fbb034' }} />
          </ListItemIcon>
          <ListItemText primary={t('rateIntervener')} />
        </MenuItem>
      )}
      {!invoice_is_being_processed && (
        <MenuItem
          dense
          onClick={(e) => [setConfirmOpen(true), setContractContractorMenuOpen(null)]}
        >
          <ListItemIcon>
            <Cancel style={{ color: '#e63d53' }} />
          </ListItemIcon>
          <ListItemText primary={t('cancelAssign')} />
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <ContractorItem
        contracts={order.contracts}
        rating={rating}
        primary={<IntervenerItemPrimary />}
        contractor={contractor}
        actions={contractContractorActions}
        actionsVisible={canUpdate && contractContractorActionsVisible}
        relances={ticket.relances}
      />
      {contractContractorMenu}
      <Contract isParent={false} />

      <RelanceIntervenerMenu
        id={order._id}
        intervener={contractor?._id}
        anchorEl={relanceMenuOpen}
        dispatch={dispatch}
        onClose={() => [setRelanceMenuOpen(false), setContractContractorMenuOpen(null)]}
      />

      {canRate && (
        <RateDialog
          dispatch={dispatch}
          intervener={contractor}
          order={order}
          onClose={() => setIsRatingOpen(false)}
          open={isRatingOpen}
        />
      )}
      <ConfirmDialog
        text={<span>{t('doYouReallyWantToCancelSummons')}</span>}
        open={confirmOpen}
        onClose={(e) => [setConfirmOpen(false)]}
        onConfirm={() => dispatch(cancelIntervener, { isParent: false }, {}, { id: order._id })}
      />
    </>
  );
}

export function IntervenerItemPrimary() {
  const { order } = useContract();

  const { t } = useTranslation();
  const classesTicket = useStylesTicket();
  return (
    <Box
      display="inline-flex"
      alignItems="center"
      position="relative"
    >
      {t('intervener')}
      {order.autoassign && (
        <Tooltip
          placement="right"
          title={t('automaticallyAssigned')}
        >
          <span style={{ position: 'relative' }}>
            <FAIcon
              collection="fas"
              icon="bolt"
              className={classesTicket.pikatchu}
            />
          </span>
        </Tooltip>
      )}
    </Box>
  );
}

export function RelanceIntervenerMenu({ onClose, anchorEl, intervener, dispatch, id, technician }) {
  const notify = useNotifications();

  const { t } = useTranslation();

  const relance = (type) => {
    dispatch(
      relanceIntervener,
      { _entity: intervener, type, technician },
      { onSuccess: () => notify.success() },
      { id }
    );
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      transitionDuration={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={!!anchorEl}
      onClose={onClose}
    >
      <MenuItem onClick={() => relance('date')}>
        <ListItemIcon>
          <FAIcon
            collection="fas"
            icon="calendar"
          />
        </ListItemIcon>

        {t('reminderDate')}
      </MenuItem>
      <MenuItem onClick={() => relance('devis')}>
        <ListItemIcon>
          <FAIcon
            collection="fas"
            icon="file-search"
          />
        </ListItemIcon>

        {t('reminderQuote')}
      </MenuItem>
      <MenuItem onClick={() => relance('facture')}>
        <ListItemIcon>
          <FAIcon
            collection="fas"
            icon="file-invoice"
          />
        </ListItemIcon>

        {t('reminderInvoice')}
      </MenuItem>
      <MenuItem onClick={() => relance('update')}>
        <ListItemIcon>
          <FAIcon
            collection="fas"
            icon="info-circle"
          />
        </ListItemIcon>

        {t('reminderInfo')}
      </MenuItem>
    </Menu>
  );
}
