import { createElement, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import NotEmptyIndicator from './NotEmptyIndicator';
import { Button, Popover as MuiPopover } from '@material-ui/core';
import { useField, useForm } from 'frmx';
import classNames from 'classnames';

export default function EntityPopoverFilter({ label, entity, path, className, entityProps, initialValue = [], isLock }) {
  const { t } = useTranslation();
  const { handleSubmit, hasUpdates } = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const [is_open_popover_filter, set_is_open_popover_filter] = useState(false);

  const handle_click = (event) => {
    event.stopPropagation();
    if (isLock) return;
    set_is_open_popover_filter(!is_open_popover_filter);
    setAnchorEl(event.currentTarget);
  };

  const { setValue } = useField(path);

  return (
    <>
      <div
        onClick={(event) => handle_click(event)}
        className={`${isLock ? 'text-gray-300 cursor-not-allowed bg-gray-50' : 'hover:bg-gray-50 transition cursor-pointer text-gray-500'} relative border border-gray-300 inline-flex items-center pl-3 h-8 rounded-full text-xs font-medium whitespace-nowrap`}
      >
        <NotEmptyIndicator
          path={path}
          showIndicator
        />
        {t(label)}
        <div className="h-[30px] w-[30px] ml-1 rounded-full flex justify-center items-center">
          <FAIcon
            icon="caret-down"
            collection="fas"
            size="xs"
            className={isLock ? 'text-gray-300': 'text-gray-600'}
          />
        </div>
      </div>
      <MuiPopover
        open={is_open_popover_filter}
        transitionDuration={250}
        anchorEl={anchorEl}
        onClose={() => {
          set_is_open_popover_filter(false);
          if (hasUpdates()) handleSubmit();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -150
        }}
      >
        <div
          className={classNames(
            'relative flex flex-col z-40 h-[500px] w-[30vw] min-w-[400px] max-w-[550px]',
            className
          )}
        >
          {/* Contenu principal de la modale */}
          <div className="flex-grow overflow-auto">
            {createElement(entity, { filter: true, picker: true, pickerField: path, ...entityProps })}
          </div>

          <div className="p-1 bg-white border-t flex justify-end">
            <Button
              size="small"
              onClick={() => {
                setValue(initialValue);
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('erase')}
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('validate')}
            </Button>
          </div>
        </div>
      </MuiPopover>
    </>
  );
}
