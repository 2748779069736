import Location from 'entities/Location/Location';
import Maintenance from 'entities/Maintenance/Maintenance';
import Ticket from 'entities/Ticket/Ticket';
import AutomationManager from './Tabs/Automations';
import Documents from 'modules/documents/DMS';
import TicketFile from 'router/tickets/TicketFile';
import BoxWrapperScrollable from 'components/BoxWrapperScrollable';

const UserTabs = (configuration, element, role, auth) => {
  const company_type = auth?.interface?.type;

  return (
    !!element &&
    [
      {
        label: 'interventions',
        icon: 'files',
        count: element.tabs?.tickets,
        show: true,
        render: () => (
          <BoxWrapperScrollable>
            <TicketFile
              showFilters
              defaultFilters={{ managers: [element], locations: element._locations, jobs: element._jobs }}
              lockedFilters={['managers']}
            />
          </BoxWrapperScrollable>
        )
      },
      {
        label: 'maintenancesTitle',
        icon: 'heartbeat',
        count: element.tabs?.maintenances,
        show: configuration.feature.preventif,
        render: () => (
          <Maintenance
            disableGutters
            defaultFilters={{ managers: [element] }}
          />
        ),
        class: 'tabNoPadding'
      },
      {
        label: 'locations',
        icon: 'map-marker-alt',
        count: element._locations?.length || 0,
        show: true,
        render: () => (
          <Location
            disableGutters
            hiddenFilters={{ only: element._locations?.map((l) => l?._id || l) }}
          />
        )
      },
      {
        label: 'automatisationsTitle',
        icon: 'bolt',
        iconCollection: 'fas',
        count: element?.tabs?.automations,
        show: role?.permission('automatisations', 'read'),
        render: () => (
          <AutomationManager
            manager={element}
            company_type={company_type}
          />
        )
      },
      {
        label: 'menuItemDocuments',
        icon: 'clouds',
        count: element.tabs?.docs || 0,
        show: true,
        render: (element, isLoading) => <Documents tab_id={element._id} />
      }
    ].filter((e) => e.show)
  );
};

export default UserTabs;
