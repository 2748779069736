import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCoffee,
  faHeartbeat,
  faMapMarkerAlt,
  faWrenchSimple,
  faUserHeadset,
  faMailbox,
  faBolt,
  faUserCheck,
  faClouds,
  faMonitorHeartRate,
  faBoxBallot,
  faCalendarDay,
  faDna,
  faUserHardHat,
  faInventory,
  faCalculator,
  faAddressBook,
  faCog,
  faUserTie,
  faUser,
  faQrcode,
  faBookSpells,
  faChargingStation,
  faUserCrown,
  faDatabase,
  faMinus,
  faPalette,
  faLifeRing,
  faInfoCircle,
  faFunnelDollar,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faMapMarked,
  faRoute,
  faPowerOff,
  faSpinnerThird,
  faSpinner,
  faWifiSlash,
  faCircle,
  faVoteYea,
  faRocketLaunch,
  faCogs,
  faToolbox,
  faSitemap,
  faKey,
  faFileInvoice,
  faAsterisk,
  faHardHat,
  faTrash,
  faTrashAlt,
  faStream,
  faFiles,
  faHouseBuilding,
  faBoxTaped,
  faChartLine,
  faStreetView,
  faMoneyCheckEdit,
  faUserSecret,
  faCity,
  faShieldCheck,
  faCalendar,
  faMap,
  faLayerGroup,
  faFileChartPie,
  faCloud,
  faStickyNote,
  faSlidersV,
  faLink,
  faMailBulk,
  faHistory,
  faEraser,
  faDownload,
  faSignature,
  faClipboardCheck,
  faSave,
  faCommentsAlt,
  faCommentAlt,
  faMessage,
  faPaperclip,
  faBraille,
  faPenToSquare,
  faEye,
  faNote,
  faFileContract,
  faHouse,
  faTruckBolt,
  faImage,
  faTasks,
  faWarehouseFull,
  faParachuteBox,
  faClockRotateLeft,
  faTruck,
  faWarehouse,
  faClipboardList,
  faChessClock,
  faClock,
  faCalendarCheck,
  faGauge,
  faUserHelmetSafety,
  faLock,
  faLockOpen,
  faComment,
  faSend,
  faCheck,
  faPencil,
  faCircleCheck,
  faCircleMinus,
  faArrowUpRightFromSquare,
  faBox,
  faScrewdriverWrench,
  faImages,
  faFile,
  faMessageLines,
  faToggleOn,
  faFileImage,
  faFilePdf,
  faFileCode,
  faFileZipper,
  faFileChartColumn,
  faFolderClosed,
  faBook,
  faFolderOpen,
  faFont,
  faFileLines,
  faFileMusic,
  faFilePowerpoint,
  faFileVideo,
  faTrashCan,
  faMoneyBillTransfer,
  faWallet,
  faTrashCanSlash,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister,
  faNotes,
  faList,
  faBell,
  faArrowsRotate,
  faChevronsUp,
  faShieldHalved,
  faChartLineUp,
  faChartLineDown
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faBell,
  faShieldHalved,
  faChartLineUp,
  faChartLineDown,
  faChevronsUp,
  faArrowsRotate,
  faImages,
  faUserHelmetSafety,
  faTrashCanSlash,
  faGauge,
  faCalendarCheck,
  faBook,
  faFilePowerpoint,
  faFont,
  faFileMusic,
  faFileLines,
  faFileVideo,
  faFile,
  faFileImage,
  faFilePdf,
  faFileCode,
  faFileZipper,
  faClock,
  faChessClock,
  faTruckBolt,
  faTruck,
  faParachuteBox,
  faWarehouseFull,
  faClockRotateLeft,
  faFileContract,
  faHouse,
  faNote,
  faMessage,
  faEye,
  faPenToSquare,
  faPaperclip,
  faSave,
  faClipboardCheck,
  faSignature,
  faEraser,
  faCommentsAlt,
  faCommentAlt,
  faHistory,
  faMonitorHeartRate,
  faLink,
  faMailBulk,
  faBolt,
  faLifeRing,
  faVoteYea,
  faDownload,
  faSlidersV,
  faStickyNote,
  faDna,
  faCloud,
  faCoffee,
  faHeartbeat,
  faMapMarkerAlt,
  faWrenchSimple,
  faUserHeadset,
  faFileChartPie,
  faMailbox,
  faBraille,
  faUserCheck,
  faClouds,
  faQrcode,
  faFunnelDollar,
  faBoxBallot,
  faCalendarDay,
  faUserHardHat,
  faInventory,
  faCalculator,
  faAddressBook,
  faFolderClosed,
  faFolderOpen,
  faCog,
  faUserTie,
  faUser,
  faBookSpells,
  faChargingStation,
  faUserCrown,
  faDatabase,
  faMinus,
  faPalette,
  faInfoCircle,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faSitemap,
  faRoute,
  faPowerOff,
  faSpinnerThird,
  faSpinner,
  faWifiSlash,
  faCircle,
  faStream,
  faRocketLaunch,
  faMapMarked,
  faCogs,
  faToolbox,
  faKey,
  faFileInvoice,
  faAsterisk,
  faHardHat,
  faTrash,
  faTrashCan,
  faFileChartColumn,
  faTrashAlt,
  faFiles,
  faHouseBuilding,
  faBoxTaped,
  faChartLine,
  faStreetView,
  faMoneyCheckEdit,
  faUserSecret,
  faCity,
  faShieldCheck,
  faCalendar,
  faMap,
  faLayerGroup,
  faImage,
  faTasks,
  faWarehouse,
  faClipboardList,
  faLock,
  faLockOpen,
  faList,
  faComment,
  faSend,
  faCheck,
  faPencil,
  faCircleCheck,
  faCircleMinus,
  faArrowUpRightFromSquare,
  faNotes,
  faBox,
  faScrewdriverWrench,
  faMessageLines,
  faToggleOn,
  faMoneyBillTransfer,
  faWallet,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister
);
