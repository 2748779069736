import { createElement, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import NotEmptyIndicator from './NotEmptyIndicator';
import { Button, Popover as MuiPopover } from '@material-ui/core';
import { useField, useForm } from 'frmx';
import { getColor } from 'router/tickets/utils';

export default function EntitySelectCheckbox({
  label,
  entity,
  path,
  typePath,
  typeInitialValue,
  className,
  entityProps,
  color,
  initialValue,
  isLock
}) {
  const { t } = useTranslation();
  const { handleSubmit, hasUpdates } = useForm();

  const [anchorEl, setAnchorEl] = useState(null);
  const [is_open_popover_filter, set_is_open_popover_filter] = useState(false);

  const { value, setValue } = useField(typePath);
  const { setValue: setListValue } = useField(path);

  const {
    activeIconColor,
    inactiveIconColor,
    activeTextColor,
    inactiveBorderColor,
    activeBorderColor
  } = getColor(color);

  const handle_click = (event) => {
    event.stopPropagation();
    if (isLock) return;
    set_is_open_popover_filter(!is_open_popover_filter);
    setAnchorEl(event.currentTarget);
    if (!is_open_popover_filter && !value.includes('maintenance')) {
      setValue([...value, 'maintenance']);
    }
  };

  const toggleValue = (event, item) => {
    event.stopPropagation();
    if (isLock) return;
    const newValue = value.includes(item) ? value.filter((val) => val !== item) : [...value, item];

    setValue(newValue);
    if (!newValue.includes(item)) setListValue([]);
    handleSubmit();
  };

  const isChecked = value.includes('maintenance');

  return (
    <>
      <div
        onClick={(e) => toggleValue(e, 'maintenance')}
        className={`${
          isLock
            ? 'text-gray-300 bg-gray-50 cursor-not-allowed'
            : 'cursor-pointer text-gray-500 hover:bg-gray-50 transition'
        } border relative ${isChecked ? activeBorderColor : inactiveBorderColor} ${
          isChecked ? activeTextColor : ''
        } inline-flex items-center pl-2 h-8 rounded-full text-xs font-medium`}
      >
        <FAIcon
          collection={isChecked ? 'fas' : 'fal'}
          icon={isChecked ? 'circle-check' : 'circle'}
          size="small"
          className={`mr-1 ${isChecked ? activeIconColor : inactiveIconColor}`}
        />
        <NotEmptyIndicator
          path={path}
          showIndicator
        />
        {t(label)}
        <div
          onClick={handle_click}
          className={`h-[30px] w-[30px] ml-1 ${isLock ? '': 'hover:bg-gray-200'}  transition rounded-full flex justify-center items-center`}
        >
          <FAIcon
            icon="caret-down"
            collection="fas"
            size="xs"
            className={isLock ? 'text-gray-300': 'text-gray-600'}
          />
        </div>
      </div>
      <MuiPopover
        open={is_open_popover_filter}
        transitionDuration={200}
        anchorEl={anchorEl}
        onClose={() => {
          set_is_open_popover_filter(!is_open_popover_filter);
          if (hasUpdates()) handleSubmit();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -150
        }}
      >
        <div
          className={classNames(
            'relative flex flex-col z-40 h-[500px] w-[30vw] min-w-[400px] max-w-[550px]',
            className
          )}
        >
          <div className="flex-grow overflow-auto">
            {createElement(entity, { filter: true, picker: true, pickerField: path, ...entityProps })}
          </div>
          <div className="p-1 bg-white border-t flex justify-end">
            <Button
              size="small"
              onClick={() => {
                setValue(typeInitialValue);
                setListValue(initialValue);
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('erase')}
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                set_is_open_popover_filter(false);
                handleSubmit();
              }}
            >
              {t('validate')}
            </Button>
          </div>
        </div>
      </MuiPopover>
    </>
  );
}
