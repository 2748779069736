import { formatFilter } from 'router/tickets/baseFilters';
import { accountingList } from 'store/accountingSlice';

export const fetchAccountingTickets = ({ auth, setData, dispatch, limit }) => {
  return async (filters, nextSkip = 0) => {
    setData((prevData) => ({ tickets: null, count: prevData.count || null }));

    const formattedFilters = formatFilter(filters, auth, nextSkip, limit);

    try {
      dispatch(
        accountingList,
        { filters: formattedFilters },
        {
          onSuccess: (data) => {
            setData((prevData) => ({
              tickets: data?.tickets.map((ticket) => {
                const { _id: viewId, ...otherViewProps } = ticket?.views[0] || {};
                delete ticket.views;

                return {
                  ...ticket,
                  ...otherViewProps,
                  viewId
                };
              }),
              count: nextSkip ? prevData.count : data.count
            }));
          },
          onError: () => {
            setData({ tickets: [], count: 0 });
          }
        }
      );
    } catch {
      setData({ tickets: [], count: 0 });
    }
  };
};
