import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { truncateText } from 'utils/uiUtils';
import { Box, Chip, Tooltip, useTheme } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import preventAll from 'utils/preventAll';
import useStyles from 'layouts/entities/Preview.styles';
import { ChatBubbleLeftEllipsisIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { contractStates } from 'lists/contractStates';
import { useConfiguration } from 'hooks/useConfiguration';
import { sortByLatestDateFirst } from 'utils/sort';
import { dateToLocalFormat } from 'utils/dates';
import { Ecart } from 'entities/Maintenance/PeriodPreview';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import moment from 'moment';
import classNames from 'utils/classNames';
import { useAuth } from 'hooks/useAuth';

export default function TicketPreviewNew({ ticket, activeTicket, setActiveTicket, page }) {
  const config = useConfiguration();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const auth = useAuth();

  // Get the data :
  /*------------------------------- */
  const {
    _id, //! the id of the document in the tl collection. Not to be confused with the ticket id
    p: parentTicket,
    i: ticketId,
    c: company,
    data,
    t: title,
    d: description,
    n: ticketNumber,
    m: metadata
  } = ticket;
  /*------------------------------- */
  const priority_level = metadata.find((m) => m.k === 'priorities')?.t;
  const isReschedule = metadata.find((m) => m.k === 'reschedule')?.t;

  const priority = config?.urgencies?.[priority_level];

  const { status, notes, alert, late } = data;
  const { shared: shared_notes, manager: manager_notes, internal: internal_notes } = notes;

  /*------------------------------- */
  const equipments = ticket.data.equipments || [];
  const skills = ticket.data.skills || [];
  const locations = ticket.data.locations || [];

  /*-------- Logic : instead of intervention /passage programme => en attente de retour - intervention / passage */
  const intervention_dates = metadata.find((m) => m.k === 'intervention_dates')?.t;
  const visit_date = metadata.find((m) => m.k === 'planned_dates')?.t;

  const currentStatus =
    status === 'canceled'
      ? contractStates['canceled']
      : !!ticket.del
      ? contractStates['removed']
      : isReschedule
      ? contractStates['toreplan']
      : visit_date && status === 'visit' && moment().isSameOrAfter(moment(Number(visit_date)).toISOString())
      ? contractStates['visit_devis']
      : intervention_dates &&
        status === 'intervention' &&
        moment().isSameOrAfter(moment(Number(intervention_dates)).toISOString())
      ? contractStates['intervention_finish']
      : contractStates[status];
  /*------------------------------- */

  const alertDeadlineExceed = !!alert && Date.now() > new Date(alert.date);
  const pastDeadLine = late;

  /*------------------------------- */
  const notesObject = [
    {
      notes: shared_notes,
      letter: 'V',
      title: shared_notes?.length > 1 ? t('notesVisibleByEveryone') : t('noteVisibleByEveryone'),
      color: '#297BD7'
    },
    {
      notes: internal_notes,
      letter: 'I',
      title: internal_notes?.length > 1 ? t('internalNotes') : t('internalNote'),
      color: '#1F60A7'
    },
    config.isManager && {
      notes: manager_notes,
      letter: 'G',
      title: manager_notes?.length > 1 ? t('managersNotes') : t('managersNote'),
      color: '#174578'
    }
  ];

  /*------------------------------- */
  // Front design :
  /*------------------------------- */
  return (
    <div
      className={classNames(
        'border-b w-full h-full p-4 bg-white hover:bg-stone-50 cursor-pointer transition duration-75',
        activeTicket === ticketId && 'bg-stone-100'
      )}
      onClick={() => {
        const next = activeTicket !== ticketId ? ticketId : null;
        setActiveTicket(next);

        if (page) {
          history.push(next ? `/tickets/${next}` : `/t`);
        }
      }}
    >
      {/*------------ 1er ligne : Nbr ticket, priorite, notes, alert, hors-delai */}
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center space-x-2">
          <TicketNumber ticketNumber={ticketNumber} />
          <TicketState
            t={t}
            currentStatus={currentStatus}
          />
          <Alert
            t={t}
            alert={alert}
            theme={theme}
            alertDeadlineExceed={alertDeadlineExceed}
          />
          <EcartPeriod ticket={ticket} />
        </div>
        <div className="flex items-center">
          <Notes
            t={t}
            notesObject={notesObject}
            classes={classes}
          />
          <TicketPastDeadline
            t={t}
            pastDeadLine={pastDeadLine}
          />
          <TicketPriority
            t={t}
            priority={priority}
            classes={classes}
            isActive={activeTicket === ticketId}
          />
        </div>
      </div>

      {/*------------ 2eme ligne : Titre et Metier  */}
      <div className="flex flex-col sm:flex-row justify-between items-start gap-2 text-sm my-2">
        <Title
          t={t}
          title={auth.interface.isMaster ? ticket.data?.client?.name || ticket.data?.contractor?.name : title}
        />
        <Skills
          t={t}
          skills={skills}
        />
      </div>
      {/*------------ 3eme ligne : Description  */}
      <Description
        t={t}
        description={description}
      />

      {/*------------ 4eme ligne : Lieu et Equipement  */}
      <div className="flex flex-col sm:flex-row justify-between items-start text-xs mt-2">
        <Locations
          t={t}
          locations={locations}
        />
        <Equipments
          t={t}
          equipments={equipments}
        />
      </div>
    </div>
  );
}

/*-------------------------------------------------------------------------------------------------------------- */
/*----------------------------------------- REACT COMPONENT USED ----------------------------------------------- */

// -------------
function Title({ title }) {
  return <span className="font-medium max-w-sm">{title}</span>;
}

// -------------
function EcartPeriod({ ticket }) {
  const { period } = ticket.data;
  if (!period) return null;

  return <Ecart period={period} />;
}

const EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH = 35;
const DESCRIPTION_MAX_LENGTH = 160;

// -------------
function Skills({ t, skills }) {
  return (
    <Tooltip
      placement="right"
      title={
        skills.every((s) => s.name.length > EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
          ? skills.map((s) => s.name).join(', ')
          : ''
      }
    >
      <span className="font-normal text-xs flex items-center">
        {Boolean(skills.length) && (
          <>
            <FAIcon
              icon="toolbox"
              size="small"
              className="ml-[-4px]"
            />
            {skills.length === 1
              ? truncateText(skills[0].name, EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
              : `${skills.length} ${t('jobsS')}`}
          </>
        )}
      </span>
    </Tooltip>
  );
}

// -------------
function Description({ description }) {
  return <p className=" text-xs mt-2 mr-2">{truncateText(description, DESCRIPTION_MAX_LENGTH)}</p>;
}

// -------------
function Locations({ t, locations }) {
  return (
    <Tooltip
      placement="right"
      title={
        locations.every((l) => l.name.length > EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
          ? locations.map((l) => l.name).join(', ')
          : ''
      }
    >
      <span className="flex items-center max-w-[22rem]">
        {Boolean(locations.length) && (
          <>
            <FAIcon
              icon="map-marker-alt"
              size="small"
              className="ml-[-4px]"
            />
            {locations.length === 1
              ? truncateText(locations[0].name, EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
              : `${locations.length} ${t('locationsS')}`}
          </>
        )}
      </span>
    </Tooltip>
  );
}

// -------------
function Equipments({ t, equipments }) {
  return (
    <Tooltip
      placement="right"
      title={
        equipments.every((e) => e.name.length > EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
          ? equipments.map((e) => e.name).join(', ')
          : ''
      }
    >
      <span className="flex items-center sm:ml-4 mt-1 sm:mt-0">
        {Boolean(equipments.length) && (
          <>
            <FAIcon
              icon="wrench"
              size="small"
              className="ml-[-4px]"
            />
            {equipments.length === 1
              ? truncateText(equipments[0].name, EQUIPMENTS_LOCATIONS_SKILLS_MAX_LENGTH)
              : `${equipments.length} ${t('equipmentsS')}`}
          </>
        )}
      </span>
    </Tooltip>
  );
}

// -------------
function TicketNumber({ ticketNumber }) {
  return <span className="text-gray-700 font-semibold">#{ticketNumber}</span>; // 374151
}

// -------------
function TicketState({ t, currentStatus }) {
  return (
    <span
      className="font-semibold"
      style={{ color: currentStatus.color }}
    >
      {t(currentStatus.label)}
    </span>
  );
}

// -------------
function Alert({ t, alert, theme, alertDeadlineExceed }) {
  return (
    !!alert && (
      <Tooltip
        placement="right"
        title={
          <>
            {t('theBeforeDate')} {dateToLocalFormat(alert.date, 'PPPPp')}
            {
              <>
                <br />
                {alert.description}
              </>
            }
          </>
        }
      >
        <AccessTime
          style={{
            marginLeft: 6,
            color: alertDeadlineExceed ? theme.palette.primary.dark : theme.palette.secondary.light
          }}
        />
      </Tooltip>
    )
  );
}

// -------------
function TicketPastDeadline({ t, pastDeadLine }) {
  return (
    !!pastDeadLine && (
      <Tooltip
        placement="left"
        title={t('late')}
      >
        <Box className="flex items-center">
          <FAIcon
            style={{ marginLeft: 4, color: '#e63d53' }}
            icon="circle"
            collection="fas"
            size="small"
          />
        </Box>
      </Tooltip>
    )
  );
}

// -------------
function TicketPriority({ t, priority, classes, isActive }) {
  return (
    !!priority && (
      <span
        className={`${classes.chip} rounded-full px-2 py-[1.5px] text-[10px] border text-white transition duration-150`}
        style={
          isActive
            ? { backgroundColor: priority.color, borderColor: priority.color }
            : { color: priority.color, borderColor: priority.color }
        }
      >
        {t(priority.label)}
      </span>
    )
  );
}

// -------------
function Notes({ t, notesObject, classes }) {
  return (
    <div className="flex items-center">
      {notesObject.map(({ notes, letter, title, color }, i) => {
        return (
          !!notes?.length && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              {...preventAll}
              key={i}
              title={
                <>
                  <NoteTitle
                    notes={notes}
                    color={color}
                    title={title}
                  />
                  <div className="flow-root px-4">
                    <ul>
                      {sortByLatestDateFirst(notes).map((note, noteIdx) => {
                        const full_name_known = !!note.user?.name;
                        const note_from_api = !!note?.fromApi;

                        const author_note = note_from_api
                          ? t('fromApi')
                          : full_name_known
                          ? note?.user?.name
                          : note?.user?.email;

                        return (
                          <li key={noteIdx}>
                            <div className="relative pb-4">
                              {/* Here if the number of notes is only one no need for the gray line */}
                              {noteIdx !== notes.length - 1 ? (
                                <span
                                  className="absolute top-0 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex items-start space-x-3">
                                <NoteIconBubble />
                                <div className="group min-w-0 flex-1">
                                  <NoteText note={note} />
                                  <div className="mt-2">
                                    <p className="text-right text-xs text-gray-400">
                                      {author_note}, {dateToLocalFormat(note.created_at, 'Pp')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              }
              placement="top"
              arrow
            >
              <Box
                {...preventAll}
                style={{
                  marginRight: '.5em',
                  backgroundColor: color,
                  borderRadius: 8,
                  color: 'white',
                  fontSize: '.7em',
                  height: '2.5em',
                  width: '2.5em',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center'
                }}
              >
                {letter}
              </Box>
            </Tooltip>
          )
        );
      })}
    </div>
  );
}

//------------------- NOTE COMPONENT

function NoteTitle({ notes, color, title }) {
  return (
    <div
      className={`mb-5 p-2 text-center text-sm text-white rounded-t-lg `}
      style={{ backgroundColor: color }}
    >
      {notes?.length} {title}
    </div>
  );
}

function NoteText({ note }) {
  return (
    <div className="text-sm font-normal flex justify-between">
      <p className="whitespace-pre-wrap inline-flex">{note.note}</p>
    </div>
  );
}

function NoteIconBubble() {
  return (
    <div className="relative">
      <div className="h-5 w-5 bg-gray-100 rounded-full ring-4 ring-white flex items-center justify-center">
        <UserCircleIcon
          className="h-5 w-5 text-gray-500"
          aria-hidden="true"
        />
      </div>
      <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
        <ChatBubbleLeftEllipsisIcon
          className="h-3 w-3 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </div>
  );
}
/*-------------------------------------------------------------------------------------------------------------- */
